import {Radio} from 'antd'

const ActionSelect = ({onChange, selected, disabled, options = ['Add', 'Edit']}) => {
  const handleEvent = ({target: {value}}) => {
    onChange(value)
  }

  return (
    <Radio.Group
      className="action-select"
      options={options}
      onChange={handleEvent}
      value={selected}
      disabled={disabled}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export default ActionSelect
