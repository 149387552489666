import {Wrapper, Header, Content} from './styles'
import LogoUrl from '@assets/images/logo-white.png'
import {useSelector} from 'react-redux'
import {Outlet, Link, Navigate} from 'react-router-dom'

const HomeLayout = () => {
  console.log('TRACE', 'HomeLayout location', window.location.href)
  const isLoggedIn = useSelector(state => !!state.auth.user?.email)
  if (isLoggedIn) {
    console.log('TRACE', 'HomeLayout User is already logged in. Redirected to home.')
    return <Navigate to="/" />
  }

  return (
    <Wrapper>
      <Header>
        <Link to="/home">
          <img src={LogoUrl} alt="logo" />
        </Link>
        <Link className="login-btn" to="/auth/login">
          Sign In
        </Link>
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  )
}

export default HomeLayout
