import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'
import {useCRUD} from 'helpers/api'
import moment from 'moment'
import {useEffect} from 'react'
import {useState} from 'react'

const SiteDetails = ({id}) => {
  const [site, setSite] = useState({})
  const {read} = useCRUD('sites')

  const getSite = () => {
    read(id, response => {
      setSite({...response})
    })
  }

  useEffect(() => {
    if (id) getSite()
  }, [id])

  return (
    <Card>
      <Title className="m-0 text-lg">Site Details</Title>
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="ID">{site?.id}</Descriptions.Item>
        <Descriptions.Item label="Name">{site?.name}</Descriptions.Item>
        <Descriptions.Item label="Monitor">{site?.monitor ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Grace Period">{site?.grace_period}</Descriptions.Item>
        <Descriptions.Item label="Latitude">{site?.latitude}</Descriptions.Item>
        <Descriptions.Item label="Longitude">{site?.longitude}</Descriptions.Item>
        <Descriptions.Item label="Timestamp">{moment(site?.timestamp).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Created">{moment(site?.created).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Updated">{moment(site?.updated).local().toISOString()}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default SiteDetails
