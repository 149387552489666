import {createSlice} from '@reduxjs/toolkit'
import {stopsApi} from '@services/stops'

const initialState = {
  stops: null
}

const slice = createSlice({
  name: 'stops',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(stopsApi.endpoints.getStops.matchFulfilled, (state, {payload}) => {
        state.stops = payload
      })
      .addMatcher(stopsApi.endpoints.addStop.matchFulfilled, (state, {payload}) => {
        state.stops.unshift(payload)
      })
      .addMatcher(stopsApi.endpoints.updateStop.matchFulfilled, (state, {payload}) => {
        state.stops = state.stops.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(stopsApi.endpoints.deleteStop.matchFulfilled, (state, {payload}) => {
        state.stops = state.stops.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
