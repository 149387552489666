import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getSearch: builder.mutation({
      query: options => {
        return `search?searchTerm=${options.filterKey}&limit=${options.limit}&page=${options.page}`
      }
    })
  })
})

export const {useGetSearchMutation} = searchApi
