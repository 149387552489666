import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const temperaturesApi = createApi({
  reducerPath: 'temperaturesApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getTemperatures: builder.mutation({
      query: options => {
        return `temperatures?tgid=${options.tgid}&start=${options.start}&end=${options.end}`
      }
    })
  })
})

export const {useGetTemperaturesMutation} = temperaturesApi
