import {createSlice} from '@reduxjs/toolkit'
import {temperaturesApi} from '@services/temperatures'

const initialState = {
  temperatures: null
}

const slice = createSlice({
  name: 'temperatures',
  initialState,
  extraReducers: builder => {
    builder.addMatcher(temperaturesApi.endpoints.getTemperatures.matchFulfilled, (state, {payload}) => {
      state.temperatures = payload.temperatures
    })
  }
})

export const {logout} = slice.actions

export default slice.reducer
