import {Layout} from 'antd'
import styled from 'styled-components'

export const Wrapper = styled(Layout)`
  height: 100vh;
`

export const Header = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .login-btn {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
    color: #fff;
    border-radius: 4px;
    transition: background-color 200ms;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`

export const Content = styled(Layout.Content)`
  > div {
    height: 100%;
  }
`
