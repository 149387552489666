import {Card} from 'antd'
import ApexChart from 'react-apexcharts'
import {daysInReverse} from 'helpers/dates'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'

export const AlertCountsCard = ({query, days = 10, width}) => {
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])

  const fetchAlertsview = useFetchGet('alertsview')

  const getAlertsview = () => {
    const params = Params({...query, start: `day-${days - 1}`, count: 'days,alert_type'})
    fetchAlertsview(params, response => {
      const result = response.alertsview
      console.log('result=' + JSON.stringify(result))
      const warnings = result
        .filter(item => item.alert_type === 'temp_warning')
        .reduce((acc, item) => {
          console.log('acc', acc, item)
          const ndx = days + item.days - 1
          acc[ndx] = item.count
          console.log('acc after', acc)
          return acc
        }, Array(days).fill(0))
      console.log('warnings', warnings)
      const criticals = result
        .filter(item => item.alert_type === 'temp_critical')
        .reduce((acc, item) => {
          acc[days + item.days - 1] = item.count
          return acc
        }, Array(days).fill(0))
      setSeries([
        {name: 'Warning', data: warnings},
        {name: 'Critical', data: criticals}
      ])

      console.log('counts', warnings, criticals)
      console.log('series', series)
    })
  }

  useEffect(() => {
    if (query) getAlertsview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    let dayLabels = daysInReverse(days)
    dayLabels[0] = 'Today'
    dayLabels.reverse()

    const colors = ['#FFCC00', '#FF0000']

    setOptions({
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: colors,
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth',
        width: 1,
        colors: colors
      },
      title: {
        text: 'Alerts',
        align: 'left',
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: 'black'
        }
      },
      xaxis: {
        categories: dayLabels
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      markers: {
        size: 1,
        colors: colors,
        strokeWidth: 2
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    })
  }, [days])

  return (
    <Card className={`w-${width} h-52 font-bold`}>
      <ApexChart options={options} series={series} type="line" height={180} width={width * 0.9} />
    </Card>
  )
}
