import {createSlice} from '@reduxjs/toolkit'
import {pageSizeOptions} from 'helpers/style'
import {useDispatch, useSelector} from 'react-redux'

const initialState = {
  tenant: null,
  tenantsFilter: '',
  tenantsPage: 1,
  tenantsLimit: pageSizeOptions[3],
  sitesFilter: '',
  sitesPage: 1,
  sitesLimit: pageSizeOptions[3],
  gatewaysFilter: '',
  gatewaysPage: 1,
  gatewaysLimit: pageSizeOptions[3],
  assetsFilter: '',
  assetsPage: 1,
  assetsLimit: pageSizeOptions[3],
  alertsFilter: '',
  alertsPage: 1,
  alertsLimit: pageSizeOptions[3],
  usersFilter: '',
  usersPage: 1,
  usersLimit: pageSizeOptions[3],
  contactsFilter: '',
  contactsPage: 1,
  contactsLimit: pageSizeOptions[3],
  auditFilter: '',
  auditPage: 1,
  auditLimit: pageSizeOptions[3],
  stash: {}
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    saveTenant: (state, {payload}) => {
      if (payload) {
        return {
          ...initialState,
          tenant: payload,
          stash: {
            tenant: state.tenant,
            tenantsFilter: state.tenantsFilter,
            tenantsPage: state.tenantsPage,
            tenantsLimit: state.tenantsLimit,
            sitesFilter: state.sitesFilter,
            sitesPage: state.sitesPage,
            sitesLimit: state.sitesLimit,
            gatewaysFilter: state.gatewaysFilter,
            gatewaysPage: state.gatewaysPage,
            gatewaysLimit: state.gatewaysLimit,
            assetsFilter: state.assetsFilter,
            assetsPage: state.assetsPage,
            assetsLimit: state.assetsLimit,
            alertsFilter: state.alertsFilter,
            alertsPage: state.alertsPage,
            alertsLimit: state.alertsLimit,
            usersFilter: state.usersFilter,
            usersPage: state.usersPage,
            usersLimit: state.usersLimit,
            contactsFilter: state.contactsFilter,
            contactsPage: state.contactsPage,
            contactsLimit: state.contactsLimit,
            auditFilter: state.auditFilter,
            auditPage: state.auditPage,
            auditLimit: state.auditLimit
          }
        }
      } else if (state.tenant) {
        return {
          ...state.stash,
          stash: {}
        }
      } else {
        return {...state}
      }
    },
    saveTenantsFilter: (state, {payload}) => {
      state.tenantsFilter = payload
      state.tenantsPage = 1
      return state
    },
    saveTenantsPage: (state, {payload}) => {
      state.tenantsPage = payload
      return state
    },
    saveTenantsLimit: (state, {payload}) => {
      state.tenantsPage = 1
      state.tenantsLimit = payload
      return state
    },
    saveSitesFilter: (state, {payload}) => {
      state.sitesFilter = payload
      state.sitesPage = 1
      return state
    },
    saveSitesPage: (state, {payload}) => {
      state.sitesPage = payload
      return state
    },
    saveSitesLimit: (state, {payload}) => {
      state.sitesPage = 1
      state.sitesLimit = payload
      return state
    },
    saveGatewaysFilter: (state, {payload}) => {
      state.gatewaysFilter = payload
      state.gatewaysPage = 1
      return state
    },
    saveGatewaysPage: (state, {payload}) => {
      state.gatewaysPage = payload
      return state
    },
    saveGatewaysLimit: (state, {payload}) => {
      state.gatewaysPage = 1
      state.gatewaysLimit = payload
      return state
    },
    saveAssetsFilter: (state, {payload}) => {
      state.assetsFilter = payload
      state.assetsPage = 1
      return state
    },
    saveAssetsPage: (state, {payload}) => {
      state.assetsPage = payload
      return state
    },
    saveAssetsLimit: (state, {payload}) => {
      state.assetsPage = 1
      state.assetsLimit = payload
      return state
    },
    saveAlertsFilter: (state, {payload}) => {
      state.alertsFilter = payload
      state.alertsPage = 1
      return state
    },
    saveAlertsPage: (state, {payload}) => {
      state.alertsPage = payload
      return state
    },
    saveAlertsLimit: (state, {payload}) => {
      state.alertsPage = 1
      state.alertsLimit = payload
      return state
    },
    saveUsersFilter: (state, {payload}) => {
      state.usersFilter = payload
      state.usersPage = 1
      return state
    },
    saveUsersPage: (state, {payload}) => {
      state.usersPage = payload
      return state
    },
    saveUsersLimit: (state, {payload}) => {
      state.usersPage = 1
      state.usersLimit = payload
      return state
    },
    saveContactsFilter: (state, {payload}) => {
      state.contactsFilter = payload
      state.contactsPage = 1
      return state
    },
    saveContactsPage: (state, {payload}) => {
      state.contactsPage = payload
      return state
    },
    saveContactsLimit: (state, {payload}) => {
      state.contactsPage = 1
      state.contactsLimit = payload
      return state
    },
    saveAuditFilter: (state, {payload}) => {
      state.auditFilter = payload
      state.auditPage = 1
      return state
    },
    saveAuditPage: (state, {payload}) => {
      state.auditPage = payload
      return state
    },
    saveAuditLimit: (state, {payload}) => {
      state.auditPage = 1
      state.auditLimit = payload
      return state
    }
  }
})

export const {
  saveTenant,
  saveTenantsFilter,
  saveTenantsPage,
  saveTenantsLimit,
  saveSitesFilter,
  saveSitesPage,
  saveSitesLimit,
  saveGatewaysFilter,
  saveGatewaysPage,
  saveGatewaysLimit,
  saveAssetsFilter,
  saveAssetsPage,
  saveAssetsLimit,
  saveAlertsFilter,
  saveAlertsPage,
  saveAlertsLimit,
  saveUsersFilter,
  saveUsersPage,
  saveUsersLimit,
  saveContactsFilter,
  saveContactsPage,
  saveContactsLimit,
  saveAuditFilter,
  saveAuditPage,
  saveAuditLimit
} = slice.actions

export default slice.reducer

export const useTenant = () => {
  const tenant = useSelector(state => state.settings.tenant)
  const dispatch = useDispatch()
  const setTenant = tenant => {
    dispatch(saveTenant(tenant))
    window.scrollTo(0, 0)
  }
  return [tenant, setTenant]
}

export const useTenantsSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.tenantsFilter)
  const setFilter = filter => {
    dispatch(saveTenantsFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.tenantsPage)
  const setPage = page => {
    dispatch(saveTenantsPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.tenantsLimit)
  const setLimit = limit => {
    dispatch(saveTenantsLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useSitesSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.sitesFilter)
  const setFilter = filter => {
    dispatch(saveSitesFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.sitesPage)
  const setPage = page => {
    dispatch(saveSitesPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.sitesLimit)
  const setLimit = limit => {
    dispatch(saveSitesLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useGatewaysSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.gatewaysFilter)
  const setFilter = filter => {
    dispatch(saveGatewaysFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.gatewaysPage)
  const setPage = page => {
    dispatch(saveGatewaysPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.gatewaysLimit)
  const setLimit = limit => {
    dispatch(saveGatewaysLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useAssetsSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.assetsFilter)
  const setFilter = filter => {
    dispatch(saveAssetsFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.assetsPage)
  const setPage = page => {
    dispatch(saveAssetsPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.assetsLimit)
  const setLimit = limit => {
    dispatch(saveAssetsLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useAlertsSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.alertsFilter)
  const setFilter = filter => {
    dispatch(saveAlertsFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.alertsPage)
  const setPage = page => {
    dispatch(saveAlertsPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.alertsLimit)
  const setLimit = limit => {
    dispatch(saveAlertsLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useUsersSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.usersFilter)
  const setFilter = filter => {
    dispatch(saveUsersFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.usersPage)
  const setPage = page => {
    dispatch(saveUsersPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.usersLimit)
  const setLimit = limit => {
    dispatch(saveUsersLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useContactsSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.contactsFilter)
  const setFilter = filter => {
    dispatch(saveContactsFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.contactsPage)
  const setPage = page => {
    dispatch(saveContactsPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.contactsLimit)
  const setLimit = limit => {
    dispatch(saveContactsLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}

export const useAuditSettings = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.settings.auditFilter)
  const setFilter = filter => {
    dispatch(saveAuditFilter(filter))
    window.scrollTo(0, 0)
  }
  const page = useSelector(state => state.settings.auditPage)
  const setPage = page => {
    dispatch(saveAuditPage(page))
    window.scrollTo(0, 0)
  }
  const limit = useSelector(state => state.settings.auditLimit)
  const setLimit = limit => {
    dispatch(saveAuditLimit(limit))
    window.scrollTo(0, 0)
  }
  return [filter, setFilter, page, setPage, limit, setLimit]
}
