import {Card} from '@tremor/react'
import {Descriptions} from 'antd'
import {useSelector} from 'react-redux'

// data is gatewaysview
const LocationInfo = ({data}) => {
  const tenant = useSelector(state => state.settings.tenant)

  return (
    <Card className="max-w-sm">
      <Descriptions size="small" bordered column={1} layout="horizontal">
        {!tenant ? <Descriptions.Item label="Company">{data?.t_name}</Descriptions.Item> : <></>}
        <Descriptions.Item label="Site">{data?.s_name}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default LocationInfo
