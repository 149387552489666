import {createSlice} from '@reduxjs/toolkit'
import {gatewaysApi} from '@services/gateways'

const initialState = {
  gateways: null
}

const slice = createSlice({
  name: 'gateways',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(gatewaysApi.endpoints.getGateways.matchFulfilled, (state, {payload}) => {
        state.gateways = payload.gateways
      })
      .addMatcher(gatewaysApi.endpoints.addGateway.matchFulfilled, (state, {payload}) => {
        // state.gateways.unshift(payload)
      })
      .addMatcher(gatewaysApi.endpoints.updateGateway.matchFulfilled, (state, {payload}) => {
        // state.gateways = state.gateways.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(gatewaysApi.endpoints.deleteGateway.matchFulfilled, (state, {payload}) => {
        // state.gateways = state.gateways.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
