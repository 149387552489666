import {useTenant} from '@store/settings'
import {useSelector} from 'react-redux'
import {Wrapper} from '../styles'
import {useNavigate} from 'react-router-dom'
import Tenants from '@components/tenants'
import SitesMapRow from '@components/sites-map-row'
import {Col, Row} from 'antd'
import {TargetCountsCard} from '../target-counts-card'
import {AlertCountsCard} from '../alert-counts-card'
import {TemperatureHistogramCard} from '../temperature-histogram-card'

const widthSmall = window.innerWidth * 0.3

const TenantDashboard = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)
  const navigate = useNavigate()

  if (tenant) {
    const handleSiteClick = site => {
      if (site?.id) {
        navigate(`/site/${site?.id}`)
      }
    }

    return (
      <Wrapper>
        <Row gutter={16} style={{margin: '1em'}}>
          <Col xs={24} md={8}>
            <TargetCountsCard query={{tid: tenant?.id}} width={widthSmall} />
          </Col>
          <Col xs={24} md={8}>
            <AlertCountsCard query={{tid: tenant?.id}} days={7} width={widthSmall} />
          </Col>
          <Col xs={24} md={8}>
            <TemperatureHistogramCard query={{tid: tenant?.id}} width={widthSmall} />
          </Col>
        </Row>
        <SitesMapRow tenant={tenant} handleSiteClick={handleSiteClick} />
      </Wrapper>
    )
  } else {
    return <Tenants handleTenantChange={setTenant} />
  }
}

export default TenantDashboard
