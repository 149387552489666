import {CancelButton} from './styles'
import {Style} from './styles'
import {isSysAdmin} from 'helpers/role'

import {useState} from 'react'
import {CaretDownOutlined} from '@ant-design/icons' // Ensure this is the correct import for your setup
import {Select} from 'antd' // Ant Design Select for dropdown (or any preferred select component)

// Assuming Ant Design's Select API or replace with custom implementation
const {Option} = Select

export const TenantTitle = ({user, tenant, onClearTenant, onSelectTenant}) => {
  const [isSelecting, setIsSelecting] = useState(false)

  const handleSelect = value => {
    console.log('handleSelect..', value)
    const selectedTenant = user.tenants.find(t => t.id === value)
    user = {...user, role: selectedTenant.role, tid: selectedTenant.id}
    if (selectedTenant) {
      onSelectTenant(selectedTenant, user) // Callback to update the selected tenant
    }
    setIsSelecting(false) // Return to default display after selection
  }

  if (tenant) {
    return (
      <Style>
        <span style={{paddingRight: '1rem'}}>Company:</span>
        {isSelecting ? (
          <Select
            defaultValue={tenant.id}
            onChange={handleSelect}
            style={{width: '200px'}} // Adjust width as needed
            open={true} // Keeps the dropdown open
          >
            {user.tenants.map(t => (
              <Option key={t.id} value={t.id}>
                {t.name}
              </Option>
            ))}
          </Select>
        ) : (
          <>
            <span data-testid="selected-tenant">{tenant.name}</span>{' '}
            {user.tenants?.length > 1 && <CaretDownOutlined onClick={() => setIsSelecting(true)} />}
            {isSysAdmin(user) && tenant && (
              <CancelButton data-testid="cancel-selected-tenant" onClick={onClearTenant} />
            )}
          </>
        )}
      </Style>
    )
  } else {
    return null
  }
}
