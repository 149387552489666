import {Card} from '@tremor/react'
import {Descriptions} from 'antd'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// data is gatewaysview
const GatewayInfo = ({data}) => {
  return (
    <Card className="max-w-sm">
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="Gateway">{data?.g_name}</Descriptions.Item>
        <Descriptions.Item label="Type">{data?.g_type ? capitalizeFirstLetter(data.g_type) : ''}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default GatewayInfo
