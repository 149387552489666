import VerificationEmail from '../verification-email'
import {Wrapper} from './styles'

const ForgotPassword = () => {
  return (
    <Wrapper>
      <VerificationEmail title="Forgot Password" />
    </Wrapper>
  )
}

export default ForgotPassword
