import {Card, Row, Col} from 'antd'
import LocationsMap from './locations-map'
import {useEffect, useState} from 'react'
import {Params, useFetchGet} from 'helpers/api'
import {useGatewaysSettings} from '@store/settings'
import {paginationDefaults} from 'helpers/style'
import PageHeader from './page-header'
import ListFilter from './list-filter'
import ItemsTable from './items-table'
import {stripPrefix} from 'helpers/results'
import {MINZOOM} from 'helpers/locations'

function extractGateways(gatewaysView) {
  return gatewaysView.map(item => {
    const gateway = stripPrefix(item, 'g_')
    const railgwstatus = stripPrefix(item, 'rgs_')
    if (railgwstatus.latitude) {
      gateway.latitude = railgwstatus.latitude
      gateway.longitude = railgwstatus.longitude
    } else {
      const site = stripPrefix(item, 's_')
      gateway.latitude = site.latitude
      gateway.longitude = site.longitude
    }
    return gateway
  })
}

const GatewaysMapRow = ({site, handleGatewayClick}) => {
  const [gateways, setGateways] = useState([])
  const [gatewaysMeta, setGatewaysMeta] = useState(null)
  const fetchGateways = useFetchGet('gatewaysview')
  const [filter, setFilter, page, setPage, limit, setLimit] = useGatewaysSettings()
  const [locations, setLocations] = useState([])
  const [minZoom, setMinZoom] = useState(MINZOOM.GATEWAYS)

  const getGateways = () => {
    const params = Params({s_id: site?.id, orderBy: 'g_name', order: 'ASC', filter, limit, page})
    fetchGateways(params, response => {
      const gwsview = extractGateways(response.gatewaysview)
      setGateways(gwsview)
      setGatewaysMeta(response.meta)
      onGatewaysChange(gwsview)
    })
  }

  useEffect(() => {
    if (site?.id) getGateways()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, filter])

  const onGatewaysChange = gateways => {
    if (gateways && gateways.length > 0) {
      setLocations(
        gateways.map((gateway, index) => ({
          id: gateway.id,
          name: gateway.name, // Create a label with the order number
          latitude: gateway.latitude,
          longitude: gateway.longitude
        }))
      )
      if (gateways.some(gateway => gateway.type === 'rail')) {
        setMinZoom(MINZOOM.RAILGWS)
      }
    } else {
      // Show DT Headquarters location; leave off id to make not clickable
      setLocations([
        {
          name: site.name,
          latitude: site.latitude,
          longitude: site.longitude
        }
      ])
      setMinZoom(MINZOOM.SITES)
    }
  }

  return (
    <Row gutter={16} style={{margin: '15px'}}>
      {/* Gateways Card - takes 1/4 width */}
      <Col xs={24} md={8}>
        <Card className="max-w-md">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Gateways"
            subTitle=""
            backIcon={false}
            extra={[
              <ListFilter
                key="list-filter"
                onFilter={value => {
                  setFilter(value)
                  setPage(1)
                }}
                filter={filter}
                tenant={{id: site?.tid}}
              />
            ]}
          />
          <ItemsTable
            items={gateways}
            handleItemClick={handleGatewayClick}
            pagination={paginationDefaults(gatewaysMeta?.total, limit, gatewaysMeta?.page, setPage, setLimit)}
          />
        </Card>
      </Col>

      {/* Locations Map Card - takes 3/4 width */}
      <Col xs={24} md={16}>
        <Card className="max-w-full">
          <div style={{height: '50vh', width: '60vw'}}>
            <LocationsMap
              locations={locations}
              onLocationClick={location => handleGatewayClick(location)}
              minZoom={minZoom}
            />
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default GatewaysMapRow
