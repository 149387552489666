import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const sitesApi = createApi({
  reducerPath: 'sitesApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getSites: builder.query({
      query: options => `sites?name=${options.name}&tid=${options.tid}`
    }),
    addSite: builder.mutation({
      query: user => ({
        url: 'sites',
        method: 'POST',
        body: user
      })
    }),
    updateSite: builder.mutation({
      query: ({id, data}) => ({
        url: `sites/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteSite: builder.mutation({
      query: id => ({
        url: `sites/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetSitesQuery, useAddSiteMutation, useUpdateSiteMutation, useDeleteSiteMutation} = sitesApi
