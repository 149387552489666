import {Wrapper} from './styles'
import {MailOutlined, LockOutlined} from '@ant-design/icons'
import {beginUserSession} from '@store/index'
import {Alert, Typography, Button, Form, Input} from 'antd'
import {useState} from 'react'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'

const {Title} = Typography

const SignIn = ({expired = false}) => {
  const dispatch = useDispatch()
  // const [login, {isLoading, error}] = useLoginMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (expired) setError({status: 400})
  }, [expired])

  const onFinish = values => {
    const request = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }

    setIsLoading(true)
    fetch(process.env.REACT_APP_BACKEND_URL + '/auth/login', request)
      .then(async response => {
        return response.json()
      })
      .then(res => {
        setIsLoading(false)
        if (res?.error) {
          setError({status: 401})
        } else {
          setError({status: 200})
          dispatch(beginUserSession(res?.token, res?.user))
          localStorage.setItem('token', res?.token)
        }
      })
      .catch(err => {
        setIsLoading(false)
        setError({status: 401})
      })
  }

  return (
    <Wrapper>
      <Form initialValues={{}} onFinish={onFinish} autoComplete="off">
        {error?.originalStatus === 401 ||
          (error?.status === 401 && <Alert message={'Incorrect Email or Password!'} type="error" showIcon />)}
        {error?.status === 400 && (
          <Alert
            type={'info'}
            showIcon={true}
            message={'Your ThermalSafe session has expired'}
            description={'Please sign in to continue'}
          />
        )}

        <Title level={2}>Sign In</Title>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!'
            },
            {
              type: 'email',
              message: 'Email is not a valid email!'
            }
          ]}
        >
          <Input id="email" prefix={<MailOutlined />} placeholder="Email Address" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your Password!'
            }
          ]}
        >
          <Input id="password" prefix={<LockOutlined />} type="password" placeholder="Password" />
        </Form.Item>

        <div className="forgot-password">
          <Link to="/auth/forgotpassword">Forgot password</Link>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

export default SignIn
