import {setUser} from '@store/auth'
import {Alert, Button, Form, Input, Space, message, Typography} from 'antd'
import PageHeader from '@components/page-header'
import {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const Profile = ({onClose, onSuccess = () => {}}) => {
  const user = useSelector(state => state.auth.user)
  const token = useSelector(state => state.auth.token)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    navigate(-1)
  }

  const onFinish = values => {
    setIsLoading(true)
    const request = {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      },
      credentials: 'include'
    }
    fetch(process.env.REACT_APP_BACKEND_URL + `/usersview/me`, request)
      .then(async response => {
        if (response?.status === 400) {
          return false
        }
        return response.json()
      })
      .then(res => {
        setIsLoading(false)
        if (res) {
          onSuccess()
          handleClose()
          message.success('Successfully updated user!')
          dispatch(setUser({user: res}))
        } else {
          setError({status: 400})
        }
      })
  }

  return (
    <div>
      <PageHeader
        style={{marginLeft: '8vw'}}
        ghost={false}
        onBack={() => window.history.back()}
        title="User Profile"
        subTitle=""
        extra={[]}
      />
      <Form
        layout="horizontal"
        labelCol={{span: 3}}
        labelWrap={true}
        wrapperCol={{span: 10, offset: 0}}
        initialValues={user}
        onFinish={onFinish}
        autoComplete="off"
        key={user ? user.id || -1 : 0}
      >
        {error && (
          <Alert
            message={error?.status === 400 ? 'User with this email already exists!' : 'Something went wrong!'}
            type="error"
            showIcon
            style={{marginBottom: 24}}
          />
        )}

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required!'
            },
            {
              min: 2,
              message: 'Must be at least 2 characters!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!'
            },
            {
              type: 'email',
              message: 'Email is not a valid email!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{span: 10, offset: 3}}>
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Profile
