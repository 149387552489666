/* eslint-disable default-case, no-fallthrough */
import ActionSelect from '@components/action-select'
import CameraSelect from '@components/camera-select'
import GatewaySelect from '@components/gateway-select'
import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Form, Input, Space, message, InputNumber, Typography} from 'antd'
import {useCRUD} from 'helpers/api'
import {useEffect} from 'react'
import {useState} from 'react'

const {Title} = Typography

export const formFields = {name: 'Name', warning: 'Warning', critical: 'Critical'}

export const buildTarget = (isNew, action, item) => {
  if (isNew || action === 'Add') {
    return {
      tid: item?.tenant?.id,
      sid: item?.site?.id,
      gid: item?.gateway?.id,
      caid: item?.camera?.id,
      name: '',
      warning: 0,
      critical: 0
    }
  } else {
    return {
      ...item?.target,
      caid: item?.target?.id ? item?.target?.caid : item?.camera?.id,
      gid: item?.gateway?.id,
      tid: item?.tenant?.id,
      sid: item?.site?.id
    }
  }
}

const TargetForm = ({target, selectedAction, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(target)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {create, read, update} = useCRUD('targets')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const fetchItem = () => {
    read(target.id, response => {
      setFormData({...response, sid: target.sid, gid: target.gid})
    })
  }

  useEffect(() => {
    if (target?.id) {
      fetchItem()
    } else {
      setFormData(target)
    }
  }, [target, selectedAction])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, selectedAction])

  const onFinish = values => {
    const body = {...values, stid: ''}

    if (!target?.id) {
      setIsLoading(true)
      create(body, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully created target!')
        } else {
          setError({status: 400})
        }
      })
    } else {
      setIsLoading(true)
      update(target.id, body, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully updated target!')
        } else {
          setError({status: 400})
        }
      })
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 10, offset: 0}}
      initialValues={formData}
      onFinish={onFinish}
      autoComplete="off"
      key={target ? target.id || -1 : 0}
      onValuesChange={changedValues => {
        let data = {...formData, ...changedValues}
        if (changedValues.tid) {
          data = {...data, sid: null, gid: null, caid: null}
        } else if (changedValues.sid) {
          data = {...data, gid: null, caid: null}
        } else if (changedValues.gid) {
          data = {...data, caid: null}
        }
        setFormData(data)
      }}
      form={form}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Target with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Target</Title>
      </Form.Item>

      <Form.Item
        label="Company"
        name="tid"
        rules={[
          {
            required: !target.tid,
            message: 'Please select a company!'
          }
        ]}
      >
        <TenantSelect disabled={!!target.tid} />
      </Form.Item>

      <Form.Item
        label="Site"
        name="sid"
        dependencies={['tid']}
        rules={[
          {
            required: !target.sid,
            message: 'Please select a site!'
          }
        ]}
      >
        <SiteSelect disabled={!!target.sid} tid={formData.tid} />
      </Form.Item>

      <Form.Item
        label="Gateway"
        name="gid"
        dependencies={['tid', 'sid']}
        rules={[
          {
            required: !target.gid,
            message: 'Please select a gateway!'
          }
        ]}
      >
        <GatewaySelect disabled={!!target.gid} sid={formData.sid} />
      </Form.Item>

      <Form.Item
        label="Camera"
        name="caid"
        dependencies={['tid', 'sid', 'gid']}
        rules={[
          {
            required: !target.caid,
            message: 'Please select a camera!'
          }
        ]}
      >
        <CameraSelect disabled={!!target.caid} gid={formData.gid} />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required!'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item className="subtitle" label="Temperature Levels" colon={false} labelCol={{span: 9}}></Form.Item>
      <Form.Item label="Warning &nbsp;&deg;C" name="warning">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Critical &nbsp;&deg;C" name="critical">
        <InputNumber />
      </Form.Item>

      <Form.Item wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            {!target?.id ? 'Add' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default TargetForm
