import './App.css'
import Router from './containers/router'
import store from './store'
import {LoadScript} from '@react-google-maps/api'
import {Provider} from 'react-redux'

export const googleApiKey = 'AIzaSyDdlhvAbLIe6hhZrQR6WeSztkwlp065RhA' //kcamApiKey

const App = () => {
  return (
    <LoadScript googleMapsApiKey={googleApiKey}>
      <Provider store={store}>
        <Router />
      </Provider>
    </LoadScript>
  )
}

export default App
