export const daysInReverse = daysBack => {
  const dayAbbreviations = []
  const today = new Date()

  for (let i = 0; i < daysBack; i++) {
    const day = new Date(today)
    day.setDate(today.getDate() - i)
    dayAbbreviations.push(day.toLocaleString('en-US', {weekday: 'short'}))
  }

  return dayAbbreviations
}
