import {useNavigate, useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import PageHeader from '@components/page-header'
import {useFetchGetId} from 'helpers/api'
import GatewaysMapRow from '@components/gateways-map-row'

const SiteDashboard = () => {
  const {sid} = useParams()
  const navigate = useNavigate()
  const [site, setSite] = useState(null)
  const fetchSite = useFetchGetId('sites')
  const [title, setTitle] = useState()

  const handleGatewayClick = gateway => {
    if (gateway?.id) {
      navigate(`/gateway/${gateway?.id}`)
    }
  }

  const getSite = () => {
    fetchSite(sid, response => {
      setSite(response)
    })
  }

  useEffect(() => {
    getSite()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    setTitle(`Site: ${site?.name}`)
  }, [site])

  return (
    <>
      <PageHeader ghost={false} onBack={() => window.history.back()} title={title} subTitle="" extra={[]} />
      <GatewaysMapRow site={site} handleGatewayClick={handleGatewayClick} />
    </>
  )
}

export default SiteDashboard
