import './viewer.css'
import {Drawer} from 'antd'
import AuditView from './audit-view'

const Viewer = ({item, onClose}) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer className="viewer" width={'50vw'} onClose={handleClose} open={!!item} closable={false}>
      <AuditView audit={item} onClose={handleClose} />
    </Drawer>
  )
}

export default Viewer
