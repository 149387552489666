import {createSlice} from '@reduxjs/toolkit'
import {searchApi} from '@services/search'

const initialState = {
  search: null,
  searchMeta: null
}

const slice = createSlice({
  name: 'search',
  initialState,
  extraReducers: builder => {
    builder.addMatcher(searchApi.endpoints.getSearch.matchFulfilled, (state, {payload}) => {
      state.search = payload.search
      state.searchMeta = payload.meta
    })
  }
})

export const {logout} = slice.actions

export default slice.reducer
