import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'

const TargetInfo = ({data}) => {
  return (
    <Card className="max-w-sm">
      {/* <Title className="m-0 text-lg">Target Info</Title> */}
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="Target">{data?.target?.name}</Descriptions.Item>
        <Descriptions.Item label="Warning Alert Temperature">{data?.target?.warning} &deg;C</Descriptions.Item>
        <Descriptions.Item label="Critical Alert Temperature">{data?.target?.critical} &deg;C</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default TargetInfo
