import {createSlice} from '@reduxjs/toolkit'
import {authApi} from '@services/auth'

const initialState = {
  user: null,
  token: localStorage.getItem('token')
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      // See rootReducer in index.js which handles resetting state on logout
    },
    setToken: (state, {payload}) => ({...state, token: payload.token}),
    setUser: (state, {payload}) => ({...state, user: payload.user})
  },
  extraReducers: builder => {
    const loggedIn = (state, payload) => {
      const {user, token} = payload
      state.token = token
      state.user = user
      localStorage.setItem('token', token)
    }
    builder
      .addMatcher(authApi.endpoints.getUserInfo.matchFulfilled, (state, {payload}) => {
        state.user = payload
      })
      .addMatcher(authApi.endpoints.getUserInfo.matchRejected, state => {
        state.token = null
        state.user = {}
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, {payload}) => {
        loggedIn(state, payload)
      })
      .addMatcher(authApi.endpoints.register.matchFulfilled, (state, {payload}) => {
        loggedIn(state, payload)
      })
  }
})

export const {logout, setToken, setUser} = slice.actions

export default slice.reducer
