import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getUsers: builder.mutation({
      query: options =>
        `users?name=${options?.name}&limit=${options?.limit}&page=${options?.page}${
          options?.tid ? `&tid=${options.tid}` : ''
        }`
    }),
    addUser: builder.mutation({
      query: user => ({
        url: 'users',
        method: 'POST',
        body: user
      })
    }),
    updateUser: builder.mutation({
      query: ({id, data}) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteUser: builder.mutation({
      query: id => ({
        url: `users/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetUsersMutation, useAddUserMutation, useUpdateUserMutation, useDeleteUserMutation} = usersApi
