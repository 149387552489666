import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px;
  background: repeating-linear-gradient(45deg, #2b2b2b, #2b2b2b 10%, #3f3f3f 0, #3f3f3f 50%) 0/15px 15px;

  h1 {
    margin: 100px 0;
    color: #fff;
    font-weight: 400;
    font-size: 4em;
    text-align: center;

    span {
      text-shadow: 0 0 10px #fff;
    }
  }
`
