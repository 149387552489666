import {createSlice} from '@reduxjs/toolkit'
import {usersApi} from '@services/users'

const initialState = {
  users: null,
  usersMeta: null
}

const slice = createSlice({
  name: 'users',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (state, {payload}) => {
        state.users = payload.users
        state.usersMeta = payload.meta
      })
      // .addMatcher(usersApi.endpoints.addUser.matchFulfilled, (state, {payload}) => {
      //   state.users.unshift(payload)
      // })
      // .addMatcher(usersApi.endpoints.updateUser.matchFulfilled, (state, {payload}) => {
      //   state.users = state.users.map(item => (item.id === payload.id ? payload : item))
      // })
      .addMatcher(usersApi.endpoints.deleteUser.matchFulfilled, (state, {payload}) => {
        state.users = state.users.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
