import {logout} from '@store/auth'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

export const GET = token => {
  return {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }
}

export const POST = (token, values) => {
  return {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }
}

export const PUT = (token, values) => {
  return {
    method: 'PUT',
    body: JSON.stringify(values),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }
}

export const DELETE = token => {
  return {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }
}

export const PATCH = (token, values) => {
  return {
    method: 'PATCH',
    body: JSON.stringify(values),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + token
    },
    credentials: 'include'
  }
}

export const Params = values => {
  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(values)) {
    if (value && (!(value instanceof String) || value.length > 0)) params.append(key, value)
  }
  return params
}

export const useFetchGet = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  return async (params, handler) => {
    const queryString = params.toString().length > 0 ? `?${params.toString()}` : ''
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}${queryString}`, GET(token))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            dispatch(logout())
            navigate(`/auth/expired`)
          } else {
            return {}
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useFetchGetId = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  return async (id, handler) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}/${id}?limit=1`, GET(token))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            dispatch(logout())
            navigate(`/auth/expired`)
          } else {
            return {}
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useFetchPost = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  //const dispatch = useDispatch()
  return async (values, handler) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}`, POST(token, values))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            //dispatch(logout())
            console.log('NAVIGATE TO /AUTH/EXPIRED')
            navigate(`/auth/expired`)
          } else {
            console.log('RETURN FALSE')
            return false
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useFetchPut = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  //const dispatch = useDispatch()
  return async (id, values, handler) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}/${id}`, PUT(token, values))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            //dispatch(logout())
            navigate(`/auth/expired`)
          } else {
            console.log('RETURN FALSE')
            return false
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useDelete = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  return async (id, handler) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}/${id}`, DELETE(token))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            navigate(`/auth/expired`)
          } else {
            console.log('RETURN FALSE')
            return false
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useFetchPatch = api => {
  const token = useSelector(state => state.auth.token)
  let navigate = useNavigate()
  //const dispatch = useDispatch()
  return async (id, values, handler) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/${api}/${id}`, PATCH(token, values))
      .then(async res => {
        console.log('response', res)
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            //dispatch(logout())
            navigate(`/auth/expired`)
          } else {
            console.log('RETURN FALSE')
            return false
          }
        } else {
          return res.json()
        }
      })
      .then(response => {
        if (typeof response !== 'undefined') handler(response)
      })
  }
}

export const useCRUD = api => {
  return {
    create: useFetchPost(api),
    read: useFetchGetId(api),
    update: useFetchPut(api),
    delete: useDelete(api)
  }
}

export const useMOVE = api => {
  return useFetchPatch(api)
}
