import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'
import {useSelector} from 'react-redux'

const LocationInfo = ({data}) => {
  const tenant = useSelector(state => state.settings.tenant)

  return (
    <Card className="max-w-sm">
      {/* <Title className="m-0 text-lg">Target Location</Title> */}
      <Descriptions size="small" bordered column={1} layout="horizontal">
        {!tenant ? <Descriptions.Item label="Company">{data?.tenant?.name}</Descriptions.Item> : <></>}
        <Descriptions.Item label="Site">{data?.site?.name}</Descriptions.Item>
        <Descriptions.Item label="Gateway">{data?.gateway?.name}</Descriptions.Item>
        <Descriptions.Item label="Camera">{data?.camera?.name}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default LocationInfo
