import Editor from './editor'
import DeleteButton from '@components/delete-button'
import ListFilter from '@components/list-filter'
import {useContactsSettings, useTenant} from '@store/settings'
import {Space, Table, Button} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useDelete, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions, paginationDefaults} from 'helpers/style'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

const getColumns = ({tenant, setTenant, setSelectedItem, doDeleteContact}) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant({id: record.tid, name: record.t_name})
          }}
        >
          {record.t_name}
        </Button>
      ),
      hidden: !!tenant
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method'
    },
    {
      title: (
        <Button
          className="add"
          type="link"
          onClick={() => {
            setSelectedItem({tid: tenant?.id})
          }}
        >
          Add
        </Button>
      ),
      key: 'action',
      align: 'right',
      width: 200,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => setSelectedItem(record)}>
            Edit
          </Button>
          {/* <EditButton record={record} editRecord={setSelectedItem} /> */}
          <DeleteButton record={record} deleteRecord={doDeleteContact} />
        </Space>
      )
    }
  ].filter(item => !item.hidden)

const Contacts = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant()
  const [filter, setFilter, page, setPage, limit, setLimit] = useContactsSettings()
  const [contacts, setSelectedItems] = useState(null)
  const [contactsMeta, setSelectedItemsMeta] = useState(null)
  const [selectedItem, setSelectedItem] = useState()
  const fetchContacts = useFetchGet('contactsview')
  const deleteContact = useDelete('contacts')

  const getContacts = () => {
    const params = Params({filter, limit, page, order: 'DESC'})
    if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
    fetchContacts(params, response => {
      setSelectedItems(response.contactsview)
      setSelectedItemsMeta(response.meta)
    })
  }

  useEffect(() => {
    getContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, limit, tenant, user])

  const data = useMemo(() => {
    return contacts
  }, [contacts])

  const doDeleteContact = id => {
    deleteContact(id, response => {
      getContacts()
    })
  }

  const columns = useMemo(() => {
    return getColumns({tenant, setTenant, setSelectedItem, doDeleteContact})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  return (
    <div id="contacts">
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Contacts"
        subTitle=""
        backIcon={false}
        extra={[
          <ListFilter
            onFilter={value => {
              setFilter(value)
              setPage(1)
            }}
            filter={filter}
          />
        ]}
      />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={!data}
        pagination={paginationDefaults(contactsMeta?.total, limit, contactsMeta?.page, setPage, setLimit)}
      />
      <Editor
        contact={selectedItem ? {...selectedItem, tid: user?.role === 'admin' ? user?.tid : selectedItem?.tid} : null}
        onClose={() => setSelectedItem(null)}
        onSuccess={() => getContacts()}
      />
    </div>
  )
}

export default Contacts
