import Details from './details'
import LocationInfo from './location-info'
import TargetControls from './target-controls'
import TargetInfo from './target-info'
import TargetLocation from './target-location'
import TargetRefImage from './target-ref-image'
import TargetTemp from './target-temp'
import TargetTempChart from './target-temp-chart'
import TargetTempImage from './target-temp-image'
import './target.css'
import Icon from '@components/icon'
import {Card, Grid, Table, TableCell, TableRow} from '@tremor/react'
import {Spin, Alert, Space} from 'antd'
import PageHeader from '@components/page-header'
import {GET, Params, useFetchGet, useFetchGetId} from 'helpers/api'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

const Target = () => {
  const {tgid} = useParams()
  const [search, setSearch] = useState(null)
  const [target, setTarget] = useState(null)
  const [period, setPeriod] = useState()
  const [temperatures, setTemperatures] = useState(null)
  const [temperature, setTemperature] = useState(null)
  const fetchSearch = useFetchGet('search')
  const fetchTarget = useFetchGetId('targets')
  const token = useSelector(state => state.auth.token)

  const getSearch = () => {
    const params = Params({searchTerm: tgid, limit: 1})
    fetchSearch(params, response => {
      setSearch(response.search[0])
    })
  }

  const getTarget = () => {
    fetchTarget(tgid, response => {
      setTarget(response)
    })
  }

  useEffect(() => {
    getSearch()
    getTarget()
    window.scrollTo(0, 0)
  }, [tgid])

  const setDaysToView = days => {
    const pastDaysToView = -(days - 1)
    const keepOffset = false
    const periodEnd = search?.target?.timestamp ? search.target.timestamp : moment().toISOString()
    const start = new moment(periodEnd)
      .local()
      .startOf('day')
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .add(pastDaysToView, 'days')
      .toISOString()
    const end = new moment(periodEnd)
      .local()
      .add(1, 'days')
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .toISOString(keepOffset)
    setPeriod({start, end})
  }

  useEffect(() => {
    if (search) setDaysToView(1)
  }, [search])

  const getTemperatures = page => {
    const request = GET(token)
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/temperatures?tgid=${tgid}&start=${period.start}&end=${period.end}&page=${page}&limit=1000`,
      request
    )
      .then(async res => {
        return res.json()
      })
      .then(response => {
        return response
      })
  }

  const moreData = meta => {
    return meta.page * meta.pageSize < meta.total
  }
  useEffect(() => {
    const loadData = async () => {
      let data = []
      let response = {meta: {page: 0}}
      do {
        response = await getTemperatures(+response.meta.page + 1)
        data = data.concat(response.temperatures)
      } while (moreData(response.meta))
      setTemperatures(data)
      setTemperature(data[data.length - 1])
    }

    if (period) {
      loadData()
    } else {
      setTemperatures(null)
    }
  }, [tgid, period])

  const title = 'Target Temperature'

  const periodDisplayRange = useMemo(() => {
    console.log('period', period)
    if (period?.start && period?.end) {
      const rangeStart = period?.start.split('T')[0]
      const rangeEnd = new moment(period.end).add(-1, 'days').toISOString().split('T')[0]
      return rangeStart === rangeEnd ? rangeStart : rangeStart + ' - ' + rangeEnd
    } else {
      return ''
    }
  }, [period])

  return (
    <main>
      <PageHeader ghost={false} onBack={() => window.history.back()} title={title} subTitle="" extra={[]} />
      <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="m-5 gap-x-20 gap-y-5">
        <LocationInfo data={{...search}} />
        <TargetInfo data={{...search, target: target}} />
        <TargetTemp target={target} temperature={temperature} />
      </Grid>
      <Card className="m-5 w-auto">
        {temperatures?.length > 0 && (
          <>
            <Space className="click-point" direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
              <Icon name="important" />
              Click on a data point to view past images
            </Space>
            <TargetTempChart target={target} temperatures={temperatures} setTemperature={setTemperature} />
          </>
        )}
        {!temperatures && (
          <div style={{minHeight: '500px'}}>
            <Spin />
          </div>
        )}
        {temperatures?.length === 0 && (
          <Alert
            message={`There are no target temperatures for this date range [${periodDisplayRange}]`}
            type="info"
            style={{margin: '20px auto', textAlign: 'center', backgroundColor: 'var(--blue-20'}}
          />
        )}
        <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
          <TargetControls setDaysToView={setDaysToView} period={period} setPeriod={setPeriod} />
        </Space>
      </Card>
      <Grid className="m-5">
        <Card>
          <Table>
            <TableRow>
              <TableCell>
                <TargetTempImage data={search} temperature={temperature} />
              </TableCell>
              <TableCell>
                <TargetRefImage data={search} temperature={temperature} />
              </TableCell>
              <TableCell>
                <TargetLocation data={search} />
              </TableCell>
            </TableRow>
          </Table>
        </Card>
      </Grid>
      <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="m-5 gap-x-6 gap-y-6">
        <Details data={search} />
      </Grid>
    </main>
  )
}

export default Target
