import CameraSelect from '@components/camera-select'
import GatewaySelect from '@components/gateway-select'
import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Divider, Form, Space, message, Typography} from 'antd'
import {useMOVE} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography

const CameraMoveForm = ({camera, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [dst, setDst] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const move = useMOVE('cameras')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const onFinish = values => {
    move(values.caid, {gid: values.dst_gid}, response => {
      setIsLoading(false)
      console.log('move response=', JSON.stringify(response))
      if (response) {
        onSuccess()
        onClose()
        handleClose()
        message.success('Successfully moved camera!')
      } else {
        setError({status: 400})
      }
    })
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 12, offset: 0}}
      initialValues={{tid: camera.tid, sid: camera.sid, gid: camera.gid, caid: [camera.id]}}
      onFinish={onFinish}
      autoComplete="off"
      key={camera.id}
      form={form}
      onValuesChange={changedValues => {
        if (changedValues.dst_tid) {
          setDst({tid: changedValues.dst_tid})
          form.setFieldValue('dst_sid', null)
          form.setFieldValue('dst_gid', null)
        } else if (changedValues.dst_sid) {
          setDst({...dst, sid: changedValues.dst_sid})
          form.setFieldValue('dst_gid', null)
        }
      }}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Camera with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Camera</Title>
      </Form.Item>

      <Divider>Selected camera to move</Divider>

      <Form.Item label="Company" name="tid">
        <TenantSelect disabled={true} />
      </Form.Item>

      <Form.Item label="Site" name="sid">
        <SiteSelect disabled={true} />
      </Form.Item>

      <Form.Item label="Gateway" name="gid">
        <GatewaySelect disabled={true} />
      </Form.Item>

      <Form.Item label="Camera" name="caid">
        <CameraSelect mode="multiple" gid={camera.gid} />
      </Form.Item>

      <Divider>Choose destination for selected camera</Divider>

      <Form.Item
        label="Company"
        name="dst_tid"
        rules={[
          {
            required: true,
            message: 'Please select a destination company!'
          }
        ]}
      >
        <TenantSelect />
      </Form.Item>

      <Form.Item
        label="Site"
        name="dst_sid"
        dependencies={['dst_tid']}
        rules={[
          {
            required: true,
            message: 'Please select a destination site!'
          }
        ]}
      >
        <SiteSelect disabled={!dst.tid} tid={dst.tid} />
      </Form.Item>

      <Form.Item
        label="Gateway"
        name="dst_gid"
        dependencies={['dst_tid', 'dst_sid']}
        rules={[
          {
            required: true,
            message: 'Please select a destination gateway!'
          }
        ]}
      >
        <GatewaySelect disabled={!dst.sid} exclude={[camera.gid]} sid={dst.sid} />
      </Form.Item>

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            Move
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CameraMoveForm
