import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Divider, Form, Input, Space, message, Switch, Typography} from 'antd'
import {useMOVE} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography

const SiteMoveForm = ({site, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const move = useMOVE('sites')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const onFinish = values => {
    move(values.sid, {tid: values.dst_tid}, response => {
      setIsLoading(false)
      console.log('move response=', JSON.stringify(response))
      if (response) {
        onSuccess()
        onClose()
        handleClose()
        message.success('Successfully moved site!')
      } else {
        setError({status: 400})
      }
    })
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 12, offset: 0}}
      initialValues={{tid: site.tid, sid: site.id}}
      onFinish={onFinish}
      autoComplete="off"
      key={site.id}
      form={form}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Site with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Site</Title>
      </Form.Item>

      <Divider>Selected site to move</Divider>

      <Form.Item label="Company" name="tid">
        <TenantSelect disabled={true} />
      </Form.Item>

      <Form.Item label="Site" name="sid">
        <SiteSelect disabled={true} />
      </Form.Item>

      <Divider>Choose destination for selected site</Divider>

      <Form.Item
        label="Company"
        name="dst_tid"
        rules={[
          {
            required: true,
            message: 'Please select a destination company!'
          }
        ]}
      >
        <TenantSelect exclude={[site.tid]} />
      </Form.Item>

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            Move
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default SiteMoveForm
