import {Wrapper} from './styles'
import {LockOutlined} from '@ant-design/icons'
import {logout} from '@store/auth'
import {Typography, Button, Form, Input, message} from 'antd'
import {jwtDecode} from 'jwt-decode'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const {Title} = Typography

const ChangePassword = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const token = useSelector(state => state.auth.token)
  const [form] = Form.useForm()

  const onFinish = values => {
    const request = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      },
      credentials: 'include'
    }

    setIsLoading(true)
    fetch(process.env.REACT_APP_BACKEND_URL + `/auth/password/${jwtDecode(token)?.sub}`, request)
      .then(async res => {
        setIsLoading(false)
        if (res?.status === 200) {
          form.setFieldsValue({password: ''})
          message.info('Your password has been changed successfully!')
        } else {
          message.error('Your session is expired! Please login again to continue!')
          dispatch(logout())
        }
      })
      .catch(err => {
        setIsLoading(false)
        message.error('Your session is expired! Please login again to continue!')
        dispatch(logout())
      })
  }

  return (
    <Wrapper>
      <Form initialValues={{}} onFinish={onFinish} autoComplete="off" form={form}>
        <>
          <Title level={2}>Change Password</Title>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required!'
              },
              {
                type: 'password',
                message: 'This is not a valid password!'
              }
            ]}
          >
            <Input type="password" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </>
      </Form>
    </Wrapper>
  )
}

export default ChangePassword
