import {createSlice} from '@reduxjs/toolkit'
import {camerasApi} from '@services/cameras'

const initialState = {
  cameras: null
}

const slice = createSlice({
  name: 'cameras',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(camerasApi.endpoints.getCameras.matchFulfilled, (state, {payload}) => {
        state.cameras = payload.cameras
      })
      .addMatcher(camerasApi.endpoints.addCamera.matchFulfilled, (state, {payload}) => {
        // state.cameras.unshift(payload)
      })
      .addMatcher(camerasApi.endpoints.updateCamera.matchFulfilled, (state, {payload}) => {
        // state.cameras = state.cameras.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(camerasApi.endpoints.deleteCamera.matchFulfilled, (state, {payload}) => {
        // state.cameras = state.cameras.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
