import {MailOutlined} from '@ant-design/icons'
import {Alert, Typography, Button, Form, Input} from 'antd'
import {useEffect} from 'react'
import {useState} from 'react'
import {useSearchParams} from 'react-router-dom'

const {Title} = Typography

const VerificationEmail = ({title}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    const code = searchParams.get('code')
    if (code) {
      const email = atob(code)
      form.setFieldValue('email', email)
    }
  }, [searchParams])

  const onFinish = values => {
    const request = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }

    setIsLoading(true)
    fetch(process.env.REACT_APP_BACKEND_URL + '/auth/forgot', request)
      .then(async response => {
        setIsLoading(false)
        if (response?.status === 200) {
          setError({status: 200})
        } else {
          setError({status: 401})
        }
      })
      .catch(err => {
        setIsLoading(false)
        setError({status: 401})
      })
  }

  return (
    <Form
      form={form}
      style={{width: '50vw', maxWidth: '50vw', textAlign: 'center'}}
      initialValues={{}}
      onFinish={onFinish}
      autoComplete="off"
    >
      {error?.originalStatus === 200 || error?.status === 200 ? (
        <>
          <Title level={3}>Email Sent!</Title>
          <Alert message="Please check your email to reset your password" type="success" />
        </>
      ) : (
        <>
          <Title level={2}>{title}</Title>
          {title === 'Forgot Password' ? (
            <Title level={5}>
              Enter your email address below and click OK to receive
              <br /> an email with a link that allows you to reset your password.
            </Title>
          ) : (
            <Title level={5}>
              A Delta Thermal account has been created for you. <p />
              Enter your email address below and click OK to receive
              <br /> an email with a link that allows you to set your password.
            </Title>
          )}
          {error?.originalStatus === 401 ||
            (error?.status === 401 && (
              <Alert message={"We didn't find any account with this email!"} type="error" showIcon />
            ))}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Email is required!'
              },
              {
                type: 'email',
                message: 'Email is not a valid email!'
              }
            ]}
          >
            <Input style={{width: '50%'}} prefix={<MailOutlined />} placeholder="Email Address" />
          </Form.Item>

          <Form.Item>
            <Button style={{width: '25%'}} type="primary" htmlType="submit" loading={isLoading}>
              OK
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default VerificationEmail
