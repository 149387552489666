import {LockOutlined} from '@ant-design/icons'
// import {useChangePasswordMutation} from '@services/auth'
import {Alert, Typography, Button, Form, Input} from 'antd'
import {jwtDecode} from 'jwt-decode'
import {useState} from 'react'
import {Link} from 'react-router-dom'

const {Title} = Typography

const ChangePassword = ({token}) => {
  console.log('TRACE', 'ChangePassword ', token)

  // const [changePassword, {isLoading, error}] = useChangePasswordMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const onFinish = values => {
    // changePassword({user_id: jwtDecode(token)?.sub, body: values})
    const request = {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      },
      credentials: 'include'
    }
    console.log('TRACE', 'ChangePassword onFinish', values)

    setIsLoading(true)
    fetch(process.env.REACT_APP_BACKEND_URL + `/auth/password/${jwtDecode(token)?.sub}`, request)
      .then(async res => {
        setIsLoading(false)
        setError({status: res?.status})
      })
      .catch(err => {
        setIsLoading(false)
        setError({status: 401})
      })
  }

  return (
    <Form initialValues={{}} onFinish={onFinish} autoComplete="off">
      {error?.originalStatus === 200 || error?.status === 200 ? (
        <>
          <Alert message="Successfully changed your password! Please sign in to continue" type="success" />
          <div className="signin">
            Click <Link to="/auth/login">here</Link> to return to the login page
          </div>
        </>
      ) : (
        <>
          <Title level={2}>Change Password</Title>
          {error?.originalStatus === 401 ||
            (error?.status === 401 && (
              <>
                <Alert message={`Unauthorized, The link has been expired!`} type="error" showIcon />
                <div className="signin" style={{marginBottom: 20}}>
                  Click <Link to="/auth/forgotpassword">here</Link> to generate the link again!
                </div>
              </>
            ))}
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required!'
              },
              {
                type: 'password',
                message: 'This is not a valid password!'
              }
            ]}
          >
            <Input type="password" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default ChangePassword
