import styled from 'styled-components'
import {CloseCircleFilled} from '@ant-design/icons'

export const Style = styled.div`
  position: absolute;
  display: inline;
  color: white;
  font-size: 1.5rem;
  margin-left: 2rem;
  z-index: 102;

  h1.ant-typography {
    color: white;
  }
`

export const CancelButton = styled(CloseCircleFilled)`
  color: gray;
`
