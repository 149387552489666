import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'
import {useCRUD} from 'helpers/api'
import moment from 'moment'
import {useEffect} from 'react'
import {useState} from 'react'

const TargetDetails = ({id}) => {
  const [target, setTarget] = useState({})
  const {read} = useCRUD('targets')

  const getTarget = () => {
    read(id, response => {
      setTarget({...response})
    })
  }

  useEffect(() => {
    if (id) getTarget()
  }, [id])

  return (
    <Card>
      <Title className="m-0 text-lg">Target Details</Title>
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="ID">{target?.id}</Descriptions.Item>
        <Descriptions.Item label="Name">{target?.name}</Descriptions.Item>
        <Descriptions.Item label="Warning Temperature Level">{target?.warning}</Descriptions.Item>
        <Descriptions.Item label="Critical Temperature Level">{target?.critical}</Descriptions.Item>
        <Descriptions.Item label="Last Readings" style={{fontWeight: 'bold'}}></Descriptions.Item>
        <Descriptions.Item label="Minimum Temperature">{target?.min}</Descriptions.Item>
        <Descriptions.Item label="Maximum Temperature">{target?.max}</Descriptions.Item>
        <Descriptions.Item label="Mean Temperature">{target?.mean}</Descriptions.Item>
        <Descriptions.Item label="Median Temperature">{target?.median}</Descriptions.Item>
        <Descriptions.Item label="External Temperature">{target?.ext_temp}</Descriptions.Item>
        <Descriptions.Item label="External Humidity">{target?.ext_humidity}</Descriptions.Item>
        <Descriptions.Item label="External Pressure">{target?.etx_pressure}</Descriptions.Item>
        <Descriptions.Item label="Timestamp">{moment(target?.timestamp).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Created">{moment(target?.created).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Updated">{moment(target?.updated).local().toISOString()}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default TargetDetails
