import {Title} from '@tremor/react'
import {Image} from 'antd'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

const TargetRefImage = ({data, temperature}) => {
  const token = useSelector(state => state.auth.token)
  const [title, setTitle] = useState()
  const [image, setImage] = useState()

  const getImage = type => {
    const request = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token
      },
      credentials: 'include'
    }
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
        `/images?tid=${data.tenant.id}&sid=${data.site.id}&gid=${data.gateway.id}&caid=${data.camera.id}&` +
        `tgid=${data.target.id}&end=${temperature.timestamp}&type=${type}&` +
        `order=desc&orderBy=timestamp&limit=1`,
      request
    )
      .then(async res => {
        return res.status === 200 ? res.json() : null
      })
      .then(response => {
        console.log('response', response)
        return response?.images?.length > 0 ? response.images[0] : null
      })
  }

  useEffect(() => {
    const getColorRefOrColorImage = async () => {
      const colorRefImage = await getImage('colorRef')
      const colorImage = colorRefImage ? null : await getImage('color')
      setImage(colorRefImage ? colorRefImage : colorImage)
      setTitle(colorRefImage || !colorImage ? 'Color Reference' : 'Color')
    }
    if (data && temperature) {
      getColorRefOrColorImage()
    } else {
      setImage(null)
      setTitle('Color Reference')
    }
  }, [data, temperature])

  if (image && image.url) {
    return (
      <span>
        <Title>{title}</Title>
        <figure className="target-ref-image">
          <Image src={image.url} />
          <figcaption>{image.timestamp ? moment(image.timestamp).local().format('yyyy/MM/DD h:mm A') : ''}</figcaption>
        </figure>
      </span>
    )
  } else {
    return (
      <>
        <Title>Color Reference</Title>
        <div className="placeholder">[No image to display]</div>
      </>
    )
  }
}

export default TargetRefImage
