import {Button, Popconfirm} from 'antd'

const DeleteButton = ({record, deleteRecord, show = true, disabled = false}) => {
  if (show) {
    return (
      <Popconfirm
        title="Are you sure delete this?"
        placement="topRight"
        onConfirm={() => deleteRecord(record.id)}
        okText="Yes"
        cancelText="No"
        disabled={disabled}
      >
        <Button type="link" disabled={disabled}>
          Delete
        </Button>
      </Popconfirm>
    )
  } else return <></>
}

export default DeleteButton
