/* eslint-disable react-hooks/exhaustive-deps */
import SiteMap from '../../../../components/site-map'
import {Title} from '@tremor/react'
import {useFetchGetId} from 'helpers/api'
import {useEffect} from 'react'
import {useState} from 'react'

const TargetLocation = ({data}) => {
  const [site, setSite] = useState(null)
  const fetchSite = useFetchGetId('sites')

  const getSite = sid => {
    fetchSite(sid, response => {
      setSite({...response})
    })
  }

  useEffect(() => {
    console.log('data', data)
    if (data?.site) getSite(data.site.id)
  }, [data])

  if (site && site.latitude && site.longitude) {
    return (
      <span>
        <Title className="m-0">Site:{data?.site?.name}</Title>
        <figure className="target-location">
          <SiteMap site={site} />
          <figcaption>{site.latitude + ',' + site.longitude}</figcaption>
        </figure>
      </span>
    )
  } else {
    return (
      <>
        <Title className="m-10 text-lg">Site: {data?.site?.name}</Title>
        <div className="placeholder">[Add site GPS coordinates to view location on Google map]</div>
      </>
    )
  }
}

export default TargetLocation
