import {Layout, Menu} from 'antd'
import styled from 'styled-components'

export const Wrapper = styled(Layout)`
  min-height: 100vh;
`

export const Header = styled(Layout.Header)`
  position: 'sticky';
  top: 0;
  zindex: 100;
  width: '100%';

  .logo {
    margin: 0 0 0 1rem;
    background: black;
    img {
      height: 60px;
    }
  }
`

export const UserMenu = styled(Menu)`
  position: absolute;
  top: 0;
  right: 0;
  zindex: 101;
  width: '25vw';
  li,
  span,
  div,
  svg,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: white;
  }
`

export const Content = styled(Layout.Content)`
  margin: 24px 24px 0;
  border: 1 red solid;
  > div {
    height: 100%;
  }
`

export const Footer = styled(Layout.Footer)`
  background-color: red;
  // background-color: var(--layout-header-background);
  color: #fff;
  text-align: center;
`
