import TenantSelect from '@components/tenant-select'
import {Drawer, Alert, Button, Form, Input, Space, Select, message} from 'antd'
import {useCRUD} from 'helpers/api'
import {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

const {Option} = Select

export const formFields = {name: 'Name', phone: 'Phone', email: 'Email', method: 'Method'}

const Editor = ({contact, onClose, onSuccess}) => {
  const currentUser = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.settings.tenant)

  const [form] = Form.useForm()
  const [phoneRequired, setPhoneRequired] = useState(false)
  const [emailRequired, setEmailRequired] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {create, update} = useCRUD('contacts')

  const isNew = !contact?.id
  // const isLoading = isNew ? addStatus.isLoading : updateStatus.isLoading
  // const error = isNew ? addStatus.error : updateStatus.error
  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const onFinish = values => {
    if (isNew) {
      setIsLoading(true)
      create(values, response => {
        if (typeof response !== 'undefined') {
          console.log('response = ' + response)
          setIsLoading(false)
          if (response) {
            onSuccess()
            onClose()
            handleClose()
            message.success('Successfully created contact!')
          } else {
            console.log('SETERROR TO 400')
            setError({status: 400})
          }
        }
      })
    } else {
      setIsLoading(true)
      update(contact.id, values, response => {
        if (typeof response !== 'undefined') {
          setIsLoading(false)
          if (response) {
            onSuccess()
            handleClose()
            message.success('Successfully updated contact!')
          } else {
            setError({status: 400})
          }
        }
      })
    }
  }

  const onValuesChange = (changedValues, allValues) => {
    const method = changedValues.method || allValues.method
    setPhoneRequired(method === 'phone' || method === 'both')
    setEmailRequired(method === 'email' || method === 'both')
    form.validateFields()
  }

  useEffect(() => {
    form.resetFields()
  }, [contact, form])

  useEffect(() => {
    form.validateFields()
  }, [form])

  return (
    <Drawer
      title={isNew ? 'Add Contact' : 'Edit Contact'}
      width={500}
      onClose={handleClose}
      open={!!contact}
      closable={false}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={contact}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        autoComplete="off"
        key={contact ? contact.id || -1 : 0}
      >
        {error && (
          <Alert
            message={error?.status === 400 ? 'Contact with this email already exists!' : 'Something went wrong!'}
            type="error"
            showIcon
            style={{marginBottom: 24}}
          />
        )}

        <Form.Item
          label="Company"
          name="tid"
          rules={[
            {
              required: true,
              message: 'Please select a company!'
            }
          ]}
        >
          <TenantSelect disabled={tenant?.id || currentUser?.role === 'admin' || !isNew} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required!'
            },
            {
              min: 2,
              message: 'Must be at least 2 characters!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            {
              required: phoneRequired,
              message: 'Phone is required!'
            },
            {
              type: 'phone',
              message: 'Phone is not a valid phone!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: emailRequired,
              message: 'Email is required!'
            },
            {
              type: 'email',
              message: 'Email is not a valid email!'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Method"
          name="method"
          rules={[
            {
              required: true,
              message: 'Please select a method!'
            }
          ]}
        >
          <Select placeholder="Select a method of contact">
            <Option value="phone">Phone</Option>
            <Option value="email">Email</Option>
            <Option value="both">Both</Option>
            <Option value="none">None</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isNew ? 'Add' : 'Save'}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default Editor
