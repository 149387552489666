/* eslint-disable no-fallthrough */
import {formFields as tenantFormFields} from '../assets/forms/tenant-form'
import {formFields as siteFormFields} from '../assets/forms/site-form'
import {formFields as gatewayFormFields} from '../assets/forms/gateway-form'
import {formFields as cameraFormFields} from '../assets/forms/camera-form'
import {formFields as targetFormFields} from '../assets/forms/target-form'
import {formFields as userFormFields} from '../users/editor'
import {formFields as contactFormFields} from '../contacts/editor'
import PageHeader from '@components/page-header'
import {Row, Col, Typography, Divider, Button} from 'antd'
import {isObject} from 'lodash'

function row(label, value) {
  if (label != 'item') {
    const val = value && isObject(value) ? JSON.stringify(value) : value
    return (
      <Row gutter={16}>
        <Col className="label" span={6} offset={0}>
          {label}:
        </Col>
        <Col span={16}>{val}</Col>
      </Row>
    )
  }
}

const AuditView = ({audit, onClose}) => {
  const title = 'Auditlog Entry'
  const isItem = audit.item_type != 'none' && 'item' in audit.data
  function itemNot(type) {
    return isItem && audit.item_type != type
  }
  function has(type, audit) {
    switch (type) {
      case 'site':
        return audit.item_type == 'gateway' || audit.item_type == 'camera' || audit.item_type == 'target'
      case 'gateway':
        return audit.item_type == 'camera' || audit.item_type == 'target'
      case 'camera':
        return audit.item_type == 'target'
      case 'target':
      default:
        return false
    }
  }

  function itemLabel(type) {
    return type.charAt(0).toUpperCase() + type.slice(1)
  }

  const formFieldsLookup = {
    tenant: tenantFormFields,
    site: siteFormFields,
    gateway: gatewayFormFields,
    camera: cameraFormFields,
    target: targetFormFields,
    user: userFormFields,
    contact: contactFormFields
  }

  const formFields = formFieldsLookup[audit.item_type] || {}

  const data = Object.keys(audit.data)
    .filter(key => key != 'item')
    .reduce((obj, key) => {
      obj[key] = audit.data[key]
      return obj
    }, {})

  const item =
    isItem &&
    Object.keys(audit.data['item'])
      .filter(key => key in formFields)
      .reduce((obj, key) => {
        obj[key] = audit.data['item'][key]
        return obj
      }, {})

  const itemRows = isItem && Object.entries(item).map(([key, value]) => row(formFields[key], value))

  return (
    <>
      <PageHeader ghost={false} onBack={() => onClose()} title={title} subTitle="" extra={[]} />
      {audit.u_name && row('User', `${audit.u_name} (${audit.uid})`)}
      {audit.ip && row('User IP', audit.ip)}
      {row('Event', `${audit.event_type} @ ${audit.created}`)}
      {row('ID', audit.id)}
      {isItem && <Divider>{itemLabel(audit.item_type)}</Divider>}
      {isItem && row('Company', `${audit.t_name} (${audit.tid})`)}
      {has('site', audit) && row('Site', `${audit.s_name} (${audit.s_id})`)}
      {has('gateway', audit) && row('Gateway', `${audit.g_name} (${audit.g_id})`)}
      {has('camera', audit) && row('Camera', `${audit.ca_name} (${audit.ca_id})`)}
      {isItem && row('ID', audit.item_id)}
      {itemRows}
      {Object.keys(data).length > 0 && <Divider>Data</Divider>}
      {Object.entries(data).map(([key, value]) => row(key.charAt(0).toUpperCase() + key.slice(1), value))}
    </>
  )
}

export default AuditView
