import {Select} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {orderBy} from 'lodash'
import {useEffect, useState} from 'react'

const {Option} = Select

const GatewaySelect = props => {
  const [name, setName] = useState('')
  const [gateways, setGateways] = useState(null)
  const fetchGateways = useFetchGet('gateways')

  const getGateways = () => {
    const params = Params({name, sid: props.sid, orderBy: 'name'})
    fetchGateways(params, response => {
      if (props.exclude) {
        setGateways(response.gateways.filter(gateway => !props.exclude.includes(gateway.id)))
      } else {
        setGateways(response.gateways)
      }
    })
  }

  useEffect(() => {
    getGateways()
  }, [name, props.sid])

  const onSearch = value => {
    setName(value)
  }

  if (props.disabled) {
    return <span className="entity">{gateways?.find(gateway => gateway.id === props.value)?.name}</span>
  } else {
    return (
      <Select
        placeholder="Select a gateway"
        loading={!gateways}
        showSearch
        {...(gateways ? props : {})}
        onSearch={onSearch}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {gateways?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default GatewaySelect
