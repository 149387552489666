import {Card, Title} from '@tremor/react'
import {Descriptions} from 'antd'
import {useCRUD} from 'helpers/api'
import moment from 'moment'
import {useEffect} from 'react'
import {useState} from 'react'

const GatewayDetails = ({id}) => {
  const [gateway, setGateway] = useState({})
  const {read} = useCRUD('gateways')

  const getGateway = () => {
    read(id, response => {
      setGateway({...response})
    })
  }

  useEffect(() => {
    if (id) getGateway()
  }, [id])

  return (
    <Card>
      <Title className="m-0 text-lg">Gateway Details</Title>
      <Descriptions size="small" bordered column={1} layout="horizontal">
        <Descriptions.Item label="ID">{gateway?.id}</Descriptions.Item>
        <Descriptions.Item label="Name">{gateway?.name}</Descriptions.Item>
        {/* <Descriptions.Item label="Monitor">{gateway?.monitor ? 'Yes' : 'No'}</Descriptions.Item> */}
        <Descriptions.Item label="Timestamp">{moment(gateway?.timestamp).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Created">{moment(gateway?.created).local().toISOString()}</Descriptions.Item>
        <Descriptions.Item label="Updated">{moment(gateway?.updated).local().toISOString()}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default GatewayDetails
