import {Card} from '@tremor/react'
import moment from 'moment/moment'

const TargetTemp = ({target, temperature}) => {
  if (target && temperature) {
    const temp = temperature.mean
    const timestamp = Math.floor(parseInt(moment(temperature.timestamp).valueOf().toString()) / 1000)
    const when = moment(temperature.timestamp).calendar()
    const timeAgo = moment(temperature.timestamp).startOf('minute').fromNow()
    let status = 'none'

    if (target && target.warning && target.critical) {
      if (temperature.mean >= target.critical) {
        status = 'critical'
      } else if (temperature.mean >= target.warning) {
        status = 'warning'
      } else {
        status = 'normal'
      }
    }

    console.log('temp:', temperature.mean, 'warning:', target.warning)

    return (
      <Card className="max-w-sm">
        <div id="target-temp">
          <div className="temp">{temp} &deg;C</div>
          <div className="when">{when}</div>
          <div className="timeAgo">{timeAgo}</div>
          <div className="timestamp">({timestamp})</div>
          <div className={'status ' + status}></div>
        </div>
      </Card>
    )
  } else {
    return <></>
  }
}

export default TargetTemp
