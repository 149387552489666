import {createSlice} from '@reduxjs/toolkit'
import {sitesApi} from '@services/sites'

const initialState = {
  sites: null
}

const slice = createSlice({
  name: 'sites',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(sitesApi.endpoints.getSites.matchFulfilled, (state, {payload}) => {
        state.sites = payload.sites
      })
      .addMatcher(sitesApi.endpoints.addSite.matchFulfilled, (state, {payload}) => {
        // state.sites = state.sites ? state.sites.unshift(payload) : [payload]
      })
      .addMatcher(sitesApi.endpoints.updateSite.matchFulfilled, (state, {payload}) => {
        // state.sites = state.sites.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(sitesApi.endpoints.deleteSite.matchFulfilled, (state, {payload}) => {
        // state.sites = state.sites.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
