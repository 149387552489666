import LocationInfo from './location-info'
import GatewayInfo from './gateway-info'
import './gateway.css'
import {Grid} from '@tremor/react'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import RailGWStatus from './railgwstatus'
import {Card} from '@tremor/react'

const Gateway = () => {
  const {gid} = useParams()
  const [gatewaysview, setGatewaysview] = useState(null)
  const fetchGatewaysview = useFetchGet('gatewaysview')
  const [title, setTitle] = useState()

  const getGatewaysView = () => {
    const params = Params({g_id: gid, limit: 1})
    fetchGatewaysview(params, response => {
      setGatewaysview(response?.gatewaysview.length === 1 ? response.gatewaysview[0] : {})
    })
  }

  useEffect(() => {
    getGatewaysView()
    window.scrollTo(0, 0)
  }, [gid])

  useEffect(() => {
    setTitle(`Gateway: ${gatewaysview?.g_name}`)
  }, [gatewaysview])

  return (
    <main>
      <PageHeader ghost={false} onBack={() => window.history.back()} title={title} subTitle="" extra={[]} />
      <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="m-5 gap-x-20 gap-y-5">
        <LocationInfo data={gatewaysview} />
        <GatewayInfo data={gatewaysview} />
      </Grid>
      {gatewaysview?.g_type === 'rail' ? (
        <Card className="m-5 w-auto">
          <RailGWStatus gateway_id={gatewaysview?.g_id} />
        </Card>
      ) : (
        ''
      )}
    </main>
  )
}

export default Gateway
