import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  form {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0px 2px 8px 0 rgb(29 35 41 / 5%);

    .ant-alert {
      margin-bottom: 12px;
    }

    .ant-typography,
    .signin {
      text-align: center;
    }

    .ant-btn {
      width: 100%;
    }
  }
`
