import {Title} from '@tremor/react'
import {Image} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'
import {useMemo} from 'react'
import {useEffect, useState} from 'react'

// Get Blend image
const TargetTempImage = ({data, temperature}) => {
  const [image, setImage] = useState(null)
  const fetchImage = useFetchGet('images')

  const getImage = () => {
    const params = Params({
      tid: data.tenant.id,
      sid: data.site.id,
      gid: data.gateway.id,
      caid: data.camera.id,
      tgid: data.target.id,
      end: temperature.timestamp,
      type: 'blend',
      order: 'desc',
      orderBy: 'timestamp',
      limit: 1
    })
    fetchImage(params, response => {
      setImage(response?.images?.length > 0 ? response.images[0] : null)
    })
  }

  useEffect(() => {
    if (temperature?.timestamp) {
      getImage()
    } else {
      setImage(null)
    }
  }, [data, temperature])

  const title = useMemo(() => {
    return 'Thermal Blend'
  })

  if (image && image.url) {
    return (
      <span>
        <Title>{title}</Title>
        <figure className="target-temp-image">
          <Image src={image.url} />
        </figure>
        <figcaption>{moment(image.timestamp).local().format('yyyy/MM/DD h:mm A')}</figcaption>
      </span>
    )
  } else {
    return (
      <>
        <Title>{title}</Title>
        <div className="placeholder">[No image to display]</div>
      </>
    )
  }
}

export default TargetTempImage
