import auth, {setToken, setUser} from './auth'
import cameras from './cameras'
import gateways from './gateways'
import search from './search'
import settings, {saveTenant} from './settings'
import sites from './sites'
import stops from './stops'
import targets from './targets'
import temperatures from './temperatures'
import tenants from './tenants'
import users from './users'
import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {authApi} from '@services/auth'
import {camerasApi} from '@services/cameras'
import {gatewaysApi} from '@services/gateways'
import {searchApi} from '@services/search'
import {sitesApi} from '@services/sites'
import {stopsApi} from '@services/stops'
import {targetsApi} from '@services/targets'
import {temperaturesApi} from '@services/temperatures'
import {tenantsApi} from '@services/tenants'
import {usersApi} from '@services/users'
import {isSysAdmin} from 'helpers/role'

const combinedReducer = combineReducers({
  auth,
  users,
  tenants,
  sites,
  cameras,
  stops,
  targets,
  search,
  gateways,
  temperatures,
  settings,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [tenantsApi.reducerPath]: tenantsApi.reducer,
  [sitesApi.reducerPath]: sitesApi.reducer,
  [camerasApi.reducerPath]: camerasApi.reducer,
  [stopsApi.reducerPath]: stopsApi.reducer,
  [targetsApi.reducerPath]: targetsApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [gatewaysApi.reducerPath]: gatewaysApi.reducer,
  [temperaturesApi.reducerPath]: temperaturesApi.reducer
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined
    localStorage.removeItem('token')
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(tenantsApi.middleware)
      .concat(sitesApi.middleware)
      .concat(camerasApi.middleware)
      .concat(stopsApi.middleware)
      .concat(targetsApi.middleware)
      .concat(searchApi.middleware)
      .concat(gatewaysApi.middleware)
      .concat(temperaturesApi.middleware)
})

export const beginUserSession = (token, user) => async dispatch => {
  dispatch(setToken({token}))
  dispatch(setUser({user}))

  // Safely filter tenants only if user.tenants exists
  const tenant =
    !user || Object.keys(user).length === 0 || isSysAdmin(user)
      ? null
      : user.tenants?.filter(item => item.id === user.tid)[0] || {id: user.tid}

  dispatch(saveTenant(tenant))
}

export default store
