import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const stopsApi = createApi({
  reducerPath: 'stopsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders
  }),

  endpoints: builder => ({
    getStops: builder.mutation({
      query: () => 'stops'
    }),
    addStop: builder.mutation({
      query: user => ({
        url: 'stops',
        method: 'POST',
        body: user
      })
    }),
    updateStop: builder.mutation({
      query: ({id, data}) => ({
        url: `stops/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteStop: builder.mutation({
      query: id => ({
        url: `stops/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetStopsMutation, useAddStopMutation, useUpdateStopMutation, useDeleteStopMutation} = stopsApi
