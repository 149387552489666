import {Button, Table} from 'antd'
import {useMemo} from 'react'
import moment from 'moment'

const getColumns = handleItemClick => [
  {
    title: 'Name',
    render: (_, record) => (
      <Button
        type="link"
        onClick={e => {
          e.stopPropagation()
          handleItemClick(record)
        }}
      >
        {record.name}
      </Button>
    )
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    render: (text, row) => {
      return moment(row.created).local().format('yyyy/MM/DD')
    }
  }
]

const ItemsTable = ({items, pagination, handleItemClick}) => {
  const columns = useMemo(() => {
    return getColumns(handleItemClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rowKey = record => {
    return `${record?.id}`
  }

  const data = useMemo(() => {
    console.log('data=' + items)
    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <Table
      data-testid="items-table"
      columns={columns}
      dataSource={data}
      loading={!data}
      pagination={pagination}
      rowKey={record => rowKey(record)}
      rowClassName="antd-table-row"
    />
  )
}

export default ItemsTable
