import {createSlice} from '@reduxjs/toolkit'
import {tenantsApi} from '@services/tenants'

const initialState = {
  tenants: null
}

const slice = createSlice({
  name: 'tenants',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(tenantsApi.endpoints.getTenants.matchFulfilled, (state, {payload}) => {
        state.tenants = payload.tenants
      })
      .addMatcher(tenantsApi.endpoints.addTenant.matchFulfilled, (state, {payload}) => {
        // state.tenants.unshift(payload)
      })
      .addMatcher(tenantsApi.endpoints.updateTenant.matchFulfilled, (state, {payload}) => {
        // state.tenants = state.tenants.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(tenantsApi.endpoints.deleteTenant.matchFulfilled, (state, {payload}) => {
        // state.tenants = state.tenants.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
