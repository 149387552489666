import TenantSelect from '@components/tenant-select'
import {Alert, Button, Form, Input, Space, message, Switch, Typography} from 'antd'
import {useCRUD} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography

const DEFAULT_GRACE_PERIOD = 120

export const formFields = {
  name: 'Name',
  latitude: 'Latitude',
  longitude: 'Longitude',
  monitor: 'Monitor',
  grace_period: 'Grace Period'
}

const minutesToHHmm = value => {
  if (value !== null) {
    return Math.floor(value / 60) + ':' + (value % 60 < 10 ? '0' : '') + (value % 60)
  } else {
    return minutesToHHmm(DEFAULT_GRACE_PERIOD)
  }
}
const hhmmToMinutes = value => {
  if (value && value !== '') {
    const hhss = value.split(':')
    return hhss[0] * 60 + +hhss[1]
  } else {
    return DEFAULT_GRACE_PERIOD
  }
}

export const buildSite = (isNew, action, item) => {
  if (isNew || action === 'Add') {
    return {
      tid: item?.tenant?.id,
      name: '',
      longitude: null,
      latitude: null,
      grace_period: minutesToHHmm(DEFAULT_GRACE_PERIOD),
      monitor: true
    }
  } else {
    return {...item?.site, tid: item?.tenant?.id}
  }
}

const SiteForm = ({site, selectedAction, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(site)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {create, read, update} = useCRUD('sites')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const fetchItem = () => {
    read(site.id, response => {
      setFormData({
        ...response,
        grace_period: minutesToHHmm(response.grace_period)
      })
    })
  }

  useEffect(() => {
    if (site?.id) {
      fetchItem()
    } else {
      setFormData(site)
    }
  }, [site, selectedAction])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, selectedAction])

  const onFinish = values => {
    values.grace_period = hhmmToMinutes(values.grace_period)

    if (!site?.id) {
      setIsLoading(true)
      create(values, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully created site!')
        } else {
          setError({status: 400})
        }
      })
    } else {
      setIsLoading(true)
      update(site.id, values, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully updated site!')
        } else {
          setError({status: 400})
        }
      })
    }
  }

  const validateLatLong = (rule, value) => {
    //console.log('validateLatLong', rule, value)
    if (value) {
      const parts = value.split(',')
      if (isNaN(parts[0]) || (parts.length > 1 && isNaN(parts[1]))) {
        return Promise.reject(new Error('GPS coordinates must be decimal numbers!'))
      }
    } else {
      return Promise.reject(new Error('Required!'))
    }
    return Promise.resolve()
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 12, offset: 0}}
      initialValues={formData}
      onFinish={onFinish}
      autoComplete="off"
      key={site ? site.id || -1 : 0}
      form={form}
      onFieldsChange={changedFields => {
        if (changedFields[0].touched) {
          const field = changedFields[0].name[0]
          const value = changedFields[0].value
          const updateFormData = {...formData}
          if ((field === 'latitude' || field === 'longitude') && value.split(',').length > 1) {
            const parts = value.split(',')
            updateFormData.latitude = parts[0]
            updateFormData.longitude = parts[1]
          } else {
            updateFormData[field] = value
          }
          setFormData(updateFormData)
        }
      }}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Site with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Site</Title>
      </Form.Item>

      <Form.Item
        label="Company"
        name="tid"
        rules={[
          {
            required: !site.tid,
            message: 'Please select a company!'
          }
        ]}
      >
        <TenantSelect disabled={!!site.tid} />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required!'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className="subtitle"
        label="GPS Coordinates (decimal degrees)"
        colon={false}
        labelCol={{span: 10}}
      ></Form.Item>
      <Form.Item
        label="Latitude"
        name="latitude"
        rules={[{required: true, message: 'Latitude is required!', validator: validateLatLong}]}
      >
        <Input placeholder="0.000000  or  0.000000,0.000000" />
      </Form.Item>
      <Form.Item
        label="Longitude"
        name="longitude"
        rules={[{required: true, message: 'Longitude is required!', validator: validateLatLong}]}
      >
        <Input placeholder="0.000000" />
      </Form.Item>

      <Form.Item label="Monitoring" name="monitor">
        <Switch checked={formData.monitor} checkedChildren={'On'} unCheckedChildren={'Off'} />
      </Form.Item>
      {!!formData.monitor && (
        <>
          <Form.Item className="subtitle" label="Connection Monitoring" colon={false} labelCol={{span: 9}}></Form.Item>
          <Form.Item
            label="Grace Period"
            name="grace_period"
            rules={[
              {
                required: true,
                message: 'Please enter grace period or disable monitoring'
              },
              {
                message: 'Please enter grace period as hh:mm',
                validator: (_, value) => {
                  console.log('value', value, typeof value)
                  if (value === '' || /^\d+:[0-5][0-9]+$/.test(value)) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject()
                  }
                }
              }
            ]}
          >
            <Input placeholder="hh:mm" />
          </Form.Item>
        </>
      )}

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            {!site?.id ? 'Add' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default SiteForm
