import {Wrapper} from './styles'

const Home = () => {
  return (
    <Wrapper>
      <h1>
        Welcome to <span>Delta Thermal Inc</span>.
      </h1>
    </Wrapper>
  )
}

export default Home
