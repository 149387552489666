import './editor.css'
import {SiteForm, SiteMoveForm, TenantForm, GatewayForm, CameraForm, TargetForm} from './forms'
import {buildCamera} from './forms/camera-form'
import CameraMoveForm from './forms/camera-move-form'
import {buildGateway} from './forms/gateway-form'
import GatewayMoveForm from './forms/gateway-move-form'
import {buildSite} from './forms/site-form'
import {buildTarget} from './forms/target-form'
import {buildTenant} from './forms/tenant-form'
import ActionSelect from '@components/action-select'
import TypeSelect from '@components/type-select'
import {Button, Drawer, Form, Switch} from 'antd'
import {isSysAdmin} from 'helpers/role'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import AuditTimeline from '../../../components/audit-timeline'

const Editor = ({item, onClose, onSuccess}) => {
  const isNew = !item?.tenant
  const user = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.settings.tenant)
  const [selectedType, setSelectedType] = useState('Company')
  const [selectedAction, setSelectedAction] = useState(isNew ? 'Add' : 'Edit')
  const onSelectType = value => {
    setSelectedType(value)
    if (!item[value === 'Company' ? 'tenant' : value.toLowerCase()]?.id && selectedAction === 'Edit') {
      setSelectedAction('Add')
    } else if (selectedAction === 'Add') {
      setSelectedAction('Edit')
    }
    console.log('item', JSON.stringify(item), 'selectedType', value)
  }
  const actions = (user, selectedType, item) => {
    let options = ['Add', 'Edit']
    if (isSysAdmin(user)) {
      if (selectedType !== 'Tenant' && selectedType !== 'Target' && item[selectedType.toLowerCase()]?.id)
        options.push('Move')
    }
    return options
  }

  const onSelectAction = value => {
    setSelectedAction(value)
  }

  useEffect(() => {
    if (isNew) {
      if (tenant) item.tenant = tenant
      setSelectedType(user?.role === 'admin' || tenant ? 'Site' : 'Company')
    } else {
      let nextSelectedType
      if (!item.site?.id) {
        nextSelectedType = 'Site'
      } else if (!item.gateway?.id) {
        nextSelectedType = 'Gateway'
      } else if (!item.camera?.id) {
        nextSelectedType = 'Camera'
      } else {
        nextSelectedType = 'Target'
      }
      setSelectedType(nextSelectedType)
      if (
        !item[nextSelectedType === 'Company' ? 'tenant' : nextSelectedType.toLowerCase()]?.id &&
        selectedAction === 'Edit'
      ) {
        setSelectedAction('Add')
      }
      console.log('useEffect', selectedType, selectedAction)
    }
  }, [isNew, user?.role, tenant, item])

  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer className="editor" width={'50vw'} onClose={handleClose} open={!!item} closable={false}>
      <Form className="controls" layout="horizontal" colon={false} labelCol={{span: 4}} wrapperCol={{span: 16}}>
        <Form.Item className="control" label="Type" name="type">
          <TypeSelect onChange={onSelectType} selected={selectedType} isNew={isNew} />
        </Form.Item>
        {(!isNew || !tenant) && !!item[selectedType === 'Company' ? 'tenant' : selectedType?.toLowerCase()]?.id && (
          <Form.Item className="control last" label="Action" name="action" labelCol={{span: 4}} wrapperCol={{span: 16}}>
            <ActionSelect
              onChange={onSelectAction}
              selected={selectedAction}
              disabled={!item[selectedType === 'Company' ? 'tenant' : selectedType?.toLowerCase()]?.id}
              options={actions(user, selectedType, item)}
            />
          </Form.Item>
        )}
      </Form>
      {selectedType === 'Company' && (selectedAction === 'Add' || selectedAction === 'Edit') && (
        <TenantForm
          tenant={buildTenant(isNew, selectedAction, item)}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
          hideActionSelect={isNew}
        />
      )}
      {selectedType === 'Site' && (selectedAction === 'Add' || selectedAction === 'Edit') && (
        <SiteForm site={buildSite(isNew, selectedAction, item)} onClose={handleClose} onSuccess={() => onSuccess()} />
      )}
      {selectedType === 'Site' && selectedAction === 'Move' && (
        <SiteMoveForm
          site={buildSite(isNew, selectedAction, item)}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {selectedType === 'Gateway' && (selectedAction === 'Add' || selectedAction === 'Edit') && (
        <GatewayForm
          gateway={buildGateway(isNew, selectedAction, item)}
          selectedAction={selectedAction}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {selectedType === 'Gateway' && selectedAction === 'Move' && (
        <GatewayMoveForm
          gateway={buildGateway(isNew, selectedAction, item)}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {selectedType === 'Camera' && (selectedAction === 'Add' || selectedAction === 'Edit') && (
        <CameraForm
          camera={buildCamera(isNew, selectedAction, item)}
          selectedAction={selectedAction}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {selectedType === 'Camera' && selectedAction === 'Move' && (
        <CameraMoveForm
          camera={buildCamera(isNew, selectedAction, item)}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {selectedType === 'Target' && (selectedAction === 'Add' || selectedAction === 'Edit') && (
        <TargetForm
          target={buildTarget(isNew, selectedAction, item)}
          selectedAction={selectedAction}
          onClose={handleClose}
          onSuccess={() => onSuccess()}
        />
      )}
      {!isNew &&
        selectedAction === 'Edit' &&
        !!item[selectedType === 'Company' ? 'tenant' : selectedType?.toLowerCase()]?.id && (
          <AuditTimeline itemId={item[selectedType === 'Company' ? 'tenant' : selectedType.toLowerCase()]?.id} />
        )}{' '}
    </Drawer>
  )
}

export default Editor
