import {useMemo} from 'react'
import {Filter} from './styles'

const ListFilter = ({onFilter, filter = '', tenant}) => {
  const key = useMemo(() => `${tenant ? tenant.id : 'all'}-filter(${filter})`, [tenant, filter])

  return (
    <Filter
      data-testid="list-filter"
      key={key}
      placeholder="Filter..."
      defaultValue={filter}
      onSearch={onFilter}
      allowClear={!!filter}
      enterButton
    />
  )
}

export default ListFilter
