/* eslint-disable no-fallthrough */
import {Table} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

const getColumns = () => [
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text, row) => {
      return moment(row.timestamp).local().format('yyyy/MM/DD h:mm:ss A')
    }
  },
  {
    title: 'Latitude',
    dataIndex: 'latitude',
    key: 'latitude'
  },
  {
    title: 'Longitude',
    dataIndex: 'longitude',
    key: 'longitude'
  },
  {
    title: 'Position Accuracy',
    dataIndex: 'position_accuracy',
    key: 'position_accuracy'
  },
  {
    title: 'Speed',
    dataIndex: 'speed',
    key: 'speed'
  },
  {
    title: 'Speed Accuracy',
    dataIndex: 'speed_accuracy',
    key: 'speed_accuracy'
  },
  {
    title: 'Heading',
    dataIndex: 'heading',
    key: 'heading'
  },
  {
    title: 'Heading Accuracy',
    dataIndex: 'heading_accuracy',
    key: 'heading_accuracy'
  },
  {
    title: 'Battery Percent',
    dataIndex: 'battery_percent',
    key: 'battery_percent'
  },
  {
    title: 'Voltage Input',
    dataIndex: 'voltage_input',
    key: 'voltage_input'
  },
  {
    title: 'Current Output',
    dataIndex: 'current_output',
    key: 'current_output'
  },
  {
    title: 'Charge State',
    dataIndex: 'charge_state',
    key: 'charge_state'
  }
]

const RailGWStatus = ({gateway_id}) => {
  const fetchRailgwstatusview = useFetchGet('railgwstatus')
  const [data, setData] = useState([])
  const [meta, setMeta] = useState({})

  useEffect(() => {
    const getRailGWStatus = () => {
      const params = Params({gid: gateway_id, orderBy: 'timestamp', order: 'DESC'})
      fetchRailgwstatusview(params, response => {
        setData(response.railgwstatus)
        setMeta(response.meta)
      })
    }

    getRailGWStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway_id])

  const columns = useMemo(() => {
    return getColumns()
  }, [])

  return <Table columns={columns} dataSource={data} rowKey={row => row.timestamp} loading={!data} />
}

export default RailGWStatus
