import {createSlice} from '@reduxjs/toolkit'
import {targetsApi} from '@services/targets'

const initialState = {
  targets: null
}

const slice = createSlice({
  name: 'targets',
  initialState,
  extraReducers: builder => {
    builder
      .addMatcher(targetsApi.endpoints.getTargets.matchFulfilled, (state, {payload}) => {
        state.targets = payload.targets
      })
      .addMatcher(targetsApi.endpoints.addTarget.matchFulfilled, (state, {payload}) => {
        // state.targets.unshift(payload)
      })
      .addMatcher(targetsApi.endpoints.updateTarget.matchFulfilled, (state, {payload}) => {
        // state.targets = state.targets.map(item => (item.id === payload.id ? payload : item))
      })
      .addMatcher(targetsApi.endpoints.deleteTarget.matchFulfilled, (state, {payload}) => {
        // state.targets = state.targets.filter(({id}) => id !== payload.id)
      })
  }
})

export const {logout} = slice.actions

export default slice.reducer
