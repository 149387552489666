import {GoogleMap, Marker} from '@react-google-maps/api'
import {MINZOOM} from 'helpers/locations'
import React, {useState, useEffect, useRef, useCallback} from 'react'

const mapContainerStyle = {
  width: '100%',
  height: '100%'
}

const createCustomMarker = text => {
  const fontSize = 14
  const padding = 10
  const pointerHeight = 10

  const textWidth = text.length * fontSize * 0.6 + padding * 2
  const markerHeight = 30 + pointerHeight

  const canvas = document.createElement('canvas')
  canvas.width = textWidth
  canvas.height = markerHeight

  const ctx = canvas.getContext('2d')
  const radius = 10
  ctx.beginPath()
  ctx.moveTo(radius, 0)
  ctx.lineTo(canvas.width - radius, 0)
  ctx.quadraticCurveTo(canvas.width, 0, canvas.width, radius)
  ctx.lineTo(canvas.width, canvas.height - pointerHeight - radius)
  ctx.quadraticCurveTo(
    canvas.width,
    canvas.height - pointerHeight,
    canvas.width - radius,
    canvas.height - pointerHeight
  )
  ctx.lineTo(canvas.width / 2 + pointerHeight, canvas.height - pointerHeight)
  ctx.lineTo(canvas.width / 2, canvas.height)
  ctx.lineTo(canvas.width / 2 - pointerHeight, canvas.height - pointerHeight)
  ctx.lineTo(radius, canvas.height - pointerHeight)
  ctx.quadraticCurveTo(0, canvas.height - pointerHeight, 0, canvas.height - pointerHeight - radius)
  ctx.lineTo(0, radius)
  ctx.quadraticCurveTo(0, 0, radius, 0)
  ctx.closePath()

  ctx.fillStyle = 'red'
  ctx.fill()

  ctx.fillStyle = 'white'
  ctx.font = `${fontSize}px Arial`
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillText(text, canvas.width / 2, (canvas.height - pointerHeight) / 2)

  return canvas.toDataURL()
}

const findLocationByPosition = (locations, position) => {
  const threshold = 0.000001

  return locations.find(location => {
    const latitude = location.latitude ? parseFloat(location.latitude.toString().trim()) : null
    const longitude = location.longitude ? parseFloat(location.longitude.toString().trim()) : null

    // Ensure latitude and longitude are numbers before comparing
    if (latitude === null || longitude === null) {
      return false
    }

    return Math.abs(latitude - position.lat()) < threshold && Math.abs(longitude - position.lng()) < threshold
  })
}

const LocationsMap = ({locations, onLocationClick = () => {}, minZoom = MINZOOM.TENANTS}) => {
  const [markers, setMarkers] = useState([])
  const mapRef = useRef(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)

  useEffect(() => {
    if (isMapLoaded && mapRef.current && locations && locations.length > 0) {
      setMarkers(
        locations.map(location => ({
          position: {
            lat: +location.latitude,
            lng: +location.longitude
          },
          label: location.name,
          draggable: false,
          clickable: !!location.id
        }))
      )

      const bounds = new window.google.maps.LatLngBounds()
      locations.forEach(location => {
        bounds.extend({
          lat: +location.latitude,
          lng: +location.longitude
        })
      })

      // Apply bounds to the map
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds)

        // Add a small delay to ensure fitBounds is applied before adjusting zoom
        setTimeout(() => {
          // Check the current zoom level and adjust if it’s too close
          const currentZoom = mapRef.current.getZoom()

          if (currentZoom > minZoom) {
            mapRef.current.setZoom(minZoom)
          }
        }, 500)
      }
    } else {
      setMarkers([])
    }
  }, [isMapLoaded, locations, minZoom])

  const mapClicked = event => {
    console.log(event.latLng.lat(), event.latLng.lng())
  }

  const onLoad = map => {
    mapRef.current = map
    setIsMapLoaded(true)
  }

  const handleTilesLoaded = useCallback(() => {
    setIsMapLoaded(true)
  }, [])

  console.log('Locations=', JSON.stringify(locations))
  console.log('Markers=' + JSON.stringify(markers))
  console.log('MINZOOM=' + minZoom)

  return (
    <GoogleMap
      id="locations-map"
      onTilesLoaded={handleTilesLoaded}
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad}
      onClick={mapClicked}
      options={{
        mapTypeId: 'roadmap',
        controlSize: 18,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true
      }}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          icon={{
            url: createCustomMarker(marker.label),
            scaledSize: new window.google.maps.Size(marker.label.length * 8 + 20, 30)
          }}
          draggable={false}
          onClick={event => onLocationClick(findLocationByPosition(locations, event.latLng))}
        />
      ))}
    </GoogleMap>
  )
}

export default LocationsMap
