import ChangePassword from '../change-password'
import {Wrapper} from '../forgot-password/styles'
import VerificationEmail from '../verification-email'
import {setToken} from '@store/auth'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useSearchParams} from 'react-router-dom'

const SetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const dipatch = useDispatch()

  console.log('TRACE', 'SetPassword searchParams=', searchParams, 'token', token)

  useEffect(() => {
    console.log('TRACE', 'SetPassword useEffect')

    if (token && token !== '') {
      console.log('TRACE', 'SetPassword useEffect', token)
      dipatch(setToken({token}))
    }
  }, [token])

  return (
    <Wrapper>
      {token && token !== '' ? <ChangePassword token={token} /> : <VerificationEmail title="Send Verification Email" />}
    </Wrapper>
  )
}

export default SetPassword
