import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const targetsApi = createApi({
  reducerPath: 'targetsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getTargets: builder.mutation({
      query: name => `targets?name=${name}`
    }),
    addTarget: builder.mutation({
      query: user => ({
        url: 'targets',
        method: 'POST',
        body: user
      })
    }),
    updateTarget: builder.mutation({
      query: ({id, data}) => ({
        url: `targets/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteTarget: builder.mutation({
      query: id => ({
        url: `targets/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetTargetsMutation, useAddTargetMutation, useUpdateTargetMutation, useDeleteTargetMutation} =
  targetsApi
