import {Radio} from 'antd'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

const TypeSelect = ({onChange, selected, isNew}) => {
  const [options, setOptions] = useState(['Loading...'])
  const user = useSelector(state => state.auth.user)
  const tenant = useSelector(state => state.settings.tenant)

  useEffect(() => {
    const types = ['Site', 'Gateway', 'Camera', 'Target']
    if (user.role === 'sysadmin' && !tenant) types.unshift('Company')
    setOptions(types)
  }, [user, tenant, selected, isNew])

  const handleEvent = ({target: {value}}) => {
    onChange(value)
  }

  return (
    <Radio.Group
      data-testid="type-select"
      className="type-select"
      options={options}
      onChange={handleEvent}
      value={selected}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export default TypeSelect
