import CameraDetails from './camera-details'
import GatewayDetails from './gateway-details'
import SiteDetails from './site-details'
import TargetDetails from './target-details'
import TenantDetails from './tenant-details'
import {Button} from '@tremor/react'
import {useState} from 'react'

const Details = ({data}) => {
  const [show, setShow] = useState(false)
  if (show) {
    return (
      <>
        <TenantDetails id={data?.tenant?.id} />
        <SiteDetails id={data?.site?.id} />
        <GatewayDetails id={data?.gateway?.id} />
        <CameraDetails id={data?.camera?.id} />
        <TargetDetails id={data?.target?.id} />
      </>
    )
  } else {
    return <Button onClick={() => setShow(true)}>Show Details</Button>
  }
}

export default Details
