/* eslint-disable no-fallthrough */
import GatewaySelect from '@components/gateway-select'
import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Form, Input, Space, Select, message, Typography} from 'antd'
import {useCRUD} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography
const {Option} = Select

export const formFields = {
  name: 'Name',
  kind: 'Type',
  hardware_id: 'Hardware ID',
  part_number: 'Part Number',
  serial_number: 'Serial Number'
}

export const buildCamera = (isNew, action, item) => {
  if (isNew || action === 'Add') {
    return {
      tid: item?.tenant?.id,
      sid: item?.site?.id,
      gid: item?.gateway?.id,
      name: '',
      kind: '',
      hardware_id: '',
      part_number: '',
      serial_number: ''
    }
  } else {
    return {
      ...item?.camera,
      gid: item?.camera?.id ? item?.camera?.gid : item?.gateway?.id,
      tid: item?.tenant?.id,
      sid: item?.site?.id
    }
  }
}

const CameraForm = ({camera, selectedAction, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState(camera)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {create, read, update} = useCRUD('cameras')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const fetchItem = () => {
    read(camera.id, response => {
      setFormData({...response, sid: camera.sid})
    })
  }

  useEffect(() => {
    if (camera?.id) {
      fetchItem()
    } else {
      setFormData(camera)
    }
  }, [camera, selectedAction])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [formData, selectedAction])

  const onFinish = values => {
    const body = {...values, stid: ''}

    if (!camera?.id) {
      setIsLoading(true)
      create(body, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully created camera!')
        } else {
          setError({status: 400})
        }
      })
    } else {
      setIsLoading(true)
      update(camera.id, body, response => {
        setIsLoading(false)
        if (response) {
          onSuccess()
          onClose()
          handleClose()
          message.success('Successfully updated camera!')
        } else {
          setError({status: 400})
        }
      })
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 10, offset: 0}}
      initialValues={formData}
      onFinish={onFinish}
      autoComplete="off"
      key={camera ? camera.id || -1 : 0}
      onValuesChange={changedValues => {
        let data = {...formData, ...changedValues}
        if (changedValues.tid) {
          data = {...data, sid: null}
        } else if (changedValues.sid) {
          data = {...data, gid: null}
        }
        setFormData(data)
      }}
      form={form}
    >
      {error && (
        <Alert
          message={
            error?.status === 400 ? 'Camera with this name or hardware id already exists!' : 'Something went wrong!'
          }
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Camera</Title>
      </Form.Item>

      <Form.Item
        label="Company"
        name="tid"
        rules={[
          {
            required: !camera.tid,
            message: 'Please select a company!'
          }
        ]}
      >
        <TenantSelect disabled={!!camera.tid} />
      </Form.Item>

      <Form.Item
        label="Site"
        name="sid"
        dependencies={['tid']}
        rules={[
          {
            required: !camera.sid,
            message: 'Please select a site!'
          }
        ]}
      >
        <SiteSelect disabled={!!camera.sid} tid={formData.tid} />
      </Form.Item>

      <Form.Item
        label="Gateway"
        name="gid"
        dependencies={['tid', 'sid']}
        rules={[
          {
            required: !camera.gid,
            message: 'Please select a gateway!'
          }
        ]}
      >
        <GatewaySelect disabled={!!camera.gid} sid={formData.sid} />
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Name is required!'
          },
          {max: 64, message: 'Max size is 64'}
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Type"
        name="kind"
        rules={[
          {
            required: true,
            message: 'Please select a camera type!'
          }
        ]}
      >
        <Select placeholder="Select a type">
          <Option value="hydra">Hydra</Option>
          <Option value="hub">Hub</Option>
          <Option value="max">Max</Option>
          <Option value="puck">Puck</Option>
          <Option value="mosaic">Mosaic</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Hardware Id" name="hardware_id" rules={[{max: 24, message: 'Max size is 24'}]}>
        <Input />
      </Form.Item>
      <Form.Item label="Part Number" name="part_number" rules={[{max: 24, message: 'Max size is 24'}]}>
        <Input />
      </Form.Item>
      <Form.Item label="Serial Number" name="serial_number" rules={[{max: 24, message: 'Max size is 24'}]}>
        <Input />
      </Form.Item>

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            {!camera?.id ? 'Add' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CameraForm
