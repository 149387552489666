import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getUserInfo: builder.mutation({
      query: id => `users/${id}`
    }),
    login: builder.mutation({
      query: credentials => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials
      })
    }),
    register: builder.mutation({
      query: credentials => ({
        url: 'register',
        method: 'POST',
        body: credentials
      })
    }),
    forgotPassword: builder.mutation({
      query: credentials => ({
        url: 'auth/forgot',
        method: 'POST',
        body: credentials
      })
    }),
    changePassword: builder.mutation({
      query: options => ({
        url: `auth/password/${options.user_id}`,
        method: 'POST',
        body: options.body
      })
    })
  })
})

export const {
  useGetUserInfoMutation,
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation
} = authApi
