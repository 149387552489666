import {capitalize} from 'lodash'

export const colors = {
  blue: '#3498DB',
  orange: '#F39C12',
  green: '#27AE60',
  purple: '#8064A2',
  blue100: '#133c84',
  blue80: '#1966c2',
  blue40: '#9EB8CF',
  blue20: '#CEDCE7',
  yellow100: '#E39F19',
  yellow80: '#f5ca44',
  black: '#000000',
  white: '#ffffff',
  gray: '#DDD8D8',
  red: '#E74C3C',
  chart: {
    warning: '#f5ca44',
    critical: '#FF0000',
    normal: '#133c84',
    ambient: '#F39C12',
    maximum: '#E74C3C',
    minimum: '#3498DB',
    median: '#27AE60'
  }
}

export const pageSizeOptions = [10, 25, 50, 100, 500, 1000]

export const paginationDefaults = (total, limit, page, setPage = () => {}, setLimit = () => {}) => {
  return {
    position: total > limit ? ['topRight', 'bottomRight'] : ['bottomRight'],
    total: total,
    current: Number(page) || 1,
    pageSize: limit,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: true,
    onChange: (_page, _limit) => {
      if (_page !== page) setPage(_page)
      if (_limit !== limit) setLimit(_limit)
      document.querySelector('main').scrollIntoView()
    },
    className: 'pagination'
  }
}
