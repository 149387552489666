import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const gatewaysApi = createApi({
  reducerPath: 'gatewaysApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getGateways: builder.mutation({
      query: options => `gateways?name=${options.name}&sid=${options.sid}`
    }),
    addGateway: builder.mutation({
      query: data => ({
        url: 'gateways',
        method: 'POST',
        body: data
      })
    }),
    updateGateway: builder.mutation({
      query: ({id, data}) => ({
        url: `gateways/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteGateway: builder.mutation({
      query: id => ({
        url: `gateways/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetGatewaysMutation, useAddGatewayMutation, useUpdateGatewayMutation, useDeleteGatewayMutation} =
  gatewaysApi
