import {baseUrl} from './_baseUrl'
import {prepareHeaders} from './_utils'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const tenantsApi = createApi({
  reducerPath: 'tenantsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders
  }),

  endpoints: builder => ({
    getTenants: builder.mutation({
      query: name => `tenants?name=${name}`
    }),
    addTenant: builder.mutation({
      query: user => ({
        url: 'tenants',
        method: 'POST',
        body: user
      })
    }),
    updateTenant: builder.mutation({
      query: ({id, data}) => ({
        url: `tenants/${id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteTenant: builder.mutation({
      query: id => ({
        url: `tenants/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {useGetTenantsMutation, useAddTenantMutation, useUpdateTenantMutation, useDeleteTenantMutation} =
  tenantsApi
