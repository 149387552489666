/* eslint-disable no-fallthrough */

import PageHeader from '@components/page-header'
import {DELTA_THERMAL_GLOBAL_HQ, MINZOOM, toLocations} from 'helpers/locations'
import LocationsMap from '@components/locations-map'
import {Card, Col, Row} from 'antd'
import {useEffect, useState} from 'react'
import Address from '@components/address'

function format(contactInfo) {
  return [
    contactInfo.name,
    contactInfo.address,
    `${contactInfo.city}, ${contactInfo.state} ${contactInfo.zipcode} ${contactInfo.country}`,
    contactInfo.email
  ]
}
const ContactUs = () => {
  const [locations, setLocations] = useState(null)

  useEffect(() => {
    setLocations(toLocations([DELTA_THERMAL_GLOBAL_HQ]))
  }, [])

  return (
    <>
      <PageHeader ghost={false} onBack={() => window.history.back()} title="Contact Us" subTitle="" backIcon={false} />
      <Row gutter={16} style={{margin: '15px'}}>
        {/* Gateways Card - takes 1/4 width */}
        <Col xs={24} md={8}>
          <Card className="max-w-md">
            <Address title="Global HQ" address={format(DELTA_THERMAL_GLOBAL_HQ)} />
          </Card>
        </Col>

        {/* Locations Map Card - takes 3/4 width */}
        <Col xs={24} md={16}>
          <Card className="max-w-full">
            <div style={{height: '50vh', width: '60vw'}}>
              <LocationsMap locations={locations} onLocationClick={location => {}} minZoom={MINZOOM.SITES} />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ContactUs
