export function findCenter(locations) {
  let totalLat = 0
  let totalLon = 0
  const numCoords = locations.length

  locations.forEach(coord => {
    totalLat += coord.latitude
    totalLon += coord.longitude
  })

  return {
    latitude: totalLat / numCoords,
    longitude: totalLon / numCoords
  }
}

export const DELTA_THERMAL_GLOBAL_HQ = {
  name: 'Delta Thermal',
  address: '6339 E Speedway Blvd',
  city: 'Tucson',
  state: 'AZ',
  zipcode: '85710',
  country: 'USA',
  latitude: 32.236177938544536,
  longitude: -110.85666744232707,
  phone: '',
  email: 'support@deltathermalinc.com'
}

export const toLocations = items => {
  return items.map(item => ({
    id: item.id,
    name: item.name,
    latitude: item.latitude ? parseFloat(item.latitude) : null,
    longitude: item.longitude ? parseFloat(item.longitude) : null
  }))
}

export const MINZOOM = {
  GATEWAY: 20,
  GATEWAYS: 18,
  RAILGW: 10,
  RAILGWS: 8,
  SITES: 6,
  TENANTS: 4
}
