import GatewaySelect from '@components/gateway-select'
import SiteSelect from '@components/site-select'
import TenantSelect from '@components/tenant-select'
import {Alert, Button, Divider, Form, Input, Space, message, Switch, Typography} from 'antd'
import {useMOVE} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Title} = Typography

const GatewayMoveForm = ({gateway, onClose, onSuccess}) => {
  const [form] = Form.useForm()
  const [dst, setDst] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const move = useMOVE('gateways')

  const handleClose = () => {
    setError(null)
    setIsLoading(false)
    onClose()
  }

  const onFinish = values => {
    move(values.gid, {sid: values.dst_sid}, response => {
      setIsLoading(false)
      console.log('move response=', JSON.stringify(response))
      if (response) {
        onSuccess()
        onClose()
        handleClose()
        message.success('Successfully moved gateway!')
      } else {
        setError({status: 400})
      }
    })
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{span: 7}}
      labelWrap={true}
      wrapperCol={{span: 12, offset: 0}}
      initialValues={{tid: gateway.tid, sid: gateway.sid, gid: gateway.id}}
      onFinish={onFinish}
      autoComplete="off"
      key={gateway.id}
      form={form}
      onValuesChange={(changedValues, allValues) => {
        console.log('onValuesChange', 'changedValues', changedValues, 'allValues', allValues)
        if (changedValues.dst_tid) {
          setDst({tid: changedValues.dst_tid})
          form.setFieldValue('dst_sid', null)
        }
      }}
    >
      {error && (
        <Alert
          message={error?.status === 400 ? 'Gateway with this name already exists!' : 'Something went wrong!'}
          type="error"
          showIcon
          style={{marginBottom: 24}}
        />
      )}

      <Form.Item className="title" wrapperCol={{span: 4, offset: 10}}>
        <Title level={4}>Gateway</Title>
      </Form.Item>

      <Divider>Selected gateway to move</Divider>

      <Form.Item label="Company" name="tid">
        <TenantSelect disabled={true} />
      </Form.Item>

      <Form.Item label="Site" name="sid">
        <SiteSelect disabled={true} />
      </Form.Item>

      <Form.Item label="Gateway" name="gid">
        <GatewaySelect disabled={true} />
      </Form.Item>

      <Divider>Choose destination for selected gateway</Divider>

      <Form.Item
        label="Company"
        name="dst_tid"
        rules={[
          {
            required: true,
            message: 'Please select a destination company!'
          }
        ]}
      >
        <TenantSelect />
      </Form.Item>

      <Form.Item
        label="Site"
        name="dst_sid"
        dependencies={['dst_tid']}
        rules={[
          {
            required: true,
            message: 'Please select a destination site!'
          }
        ]}
      >
        <SiteSelect disabled={!dst.tid} exclude={[gateway.sid]} tid={dst.tid} />
      </Form.Item>

      <Form.Item className="buttons" wrapperCol={{offset: 8}}>
        <Space>
          <Button data-testid="cancel-form" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="submit-form" type="primary" htmlType="submit" loading={isLoading}>
            Move
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default GatewayMoveForm
